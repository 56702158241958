/**
 * The global state selectors
 */

import { createSelector } from 'reselect';

const selectGlobal = state => state.global;

const selectRoute = state => state.route;

const makeSelectCurrentUser = () =>
  createSelector(selectGlobal, globalState => globalState.currentUser);

const makeSelectLoading = () =>
  createSelector(selectGlobal, globalState => globalState.loading);

const makeSelectError = () =>
  createSelector(selectGlobal, globalState => globalState.error);

const makeSelectRepos = () =>
  createSelector(selectGlobal, globalState => globalState.userData.repositories);

const makeSelectCountries = () =>
  createSelector(selectGlobal, globalState => globalState.countries);
const makeSelectFeatures = () =>
  createSelector(selectGlobal, globalState => globalState.features);
const makeSelectPackages = () =>
  createSelector(selectGlobal, globalState => globalState.packages);
const makeSelectUsertypes = () =>
  createSelector(selectGlobal, globalState => globalState.usertypes);
const makeSelectWarehouses = () =>
  createSelector(selectGlobal, globalState => globalState.warehouses);
const selectLoggedInUser = () =>
  createSelector(selectGlobal, globalState => globalState.loggedInUser);

const makeSelectLocation = () =>
  createSelector(selectRoute, routeState => routeState.location);

export {
  selectGlobal,
  makeSelectCurrentUser,
  makeSelectLoading,
  makeSelectError,
  makeSelectRepos,
  makeSelectLocation,
  makeSelectCountries,
  makeSelectFeatures,
  makeSelectPackages,
  makeSelectWarehouses,
  makeSelectUsertypes,
  selectLoggedInUser,
};
