import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Table from "../../components/Table";
import {getFormattedDateTime} from "../../utils/dateHelpers";
import UserDetail from "./UserDetail";
import { debounce } from 'lodash';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { connect, useSelector } from 'react-redux';
import * as consts from '../App/constants';
import { compose } from 'redux';
import { selectSearchParams } from './selectors';

const getLicenseType = (lic) => {
  if(lic.packages){
    let packsArray = lic.packages.map(p => p.name);
    return packsArray.join(',');
  }
  return '';
};

const getLastLoginG8 = (user) => {
  if(!user.footprints || user.footprints.length === 0) {
    return '---';
  }
  else {
    const lastLoginDate = new Date(Math.max.apply(null, user.footprints.map(function(e) { return new Date(e.lastLoginDate);})));
    return getFormattedDateTime(lastLoginDate);
  }
};

const UsersTable = (props) => {
  useInjectReducer({ key: 'home', reducer });
  useInjectSaga({ key: 'home', saga });
  const searchParams = useSelector(selectSearchParams());

  const [totalPages, setTotalPages] = useState(0);
  const [paginationPage, setPaginationPage] = useState(searchParams.page);
  const { users } = props;
  const { page, pageSize, sorting } = searchParams;

  const columns = useMemo(
  () => [
    {headerRenderer: 'First Name', key: 'firstName', cellRenderer: (data) => data.firstName},
    {headerRenderer: 'Second Name', key: 'secondName', cellRenderer: (data) => data.secondName},
    {headerRenderer: 'Email', key: 'email', cellRenderer: (data) => data.email},
    {headerRenderer: 'Company', key: 'company', cellRenderer: (data) => data.company},
    {headerRenderer: 'Country', key: 'country', cellRenderer: (data) => data.country},
    {headerRenderer: 'Created On', key: 'createdOn', cellRenderer: (data) => (<div>{getFormattedDateTime(data.createdOn) || '---'}</div>)},
    {headerRenderer: 'Last Login DThermX', key: 'lastLoginDate', cellRenderer: (data) => (<div>{getFormattedDateTime(data.lastLoginDate) || '---'}</div>)},
    {headerRenderer: 'Last Login SSP G8', key: 'footprints.lastLoginDate', cellRenderer: (data) => (<div>{getLastLoginG8(users.data.find(u => u.email === data.email))}</div>)},
    {headerRenderer: 'License Type', key: 'license', cellRenderer: (data) => (<div>{getLicenseType(data.license)}</div>)},
    {headerRenderer: 'Lic. Expiry', key: 'license.licenseExpires', cellRenderer: (data) => (<div>{getFormattedDateTime(data.license.licenseExpires)}</div>),},
    {headerRenderer: 'User Type', key: 'userType', cellRenderer: (data) => data.userType},
      {headerRenderer: 'Created on', key: 'license.createdOn', cellRenderer: (data) => (<div>{getFormattedDateTime(data.createdOn)}</div>) },
    {headerRenderer: 'Edited on', key: 'license.editedOn', cellRenderer: (data) => (<div>{getFormattedDateTime(data.license.editedOn)}</div>),},
    {headerRenderer: 'Edited by', key: 'license.editedBy', cellRenderer: (data) => data.license.editedBy || ''},
  ],[users.data]);

  useEffect(() => {
    if(users && users.metadata){
      setTotalPages(Math.ceil(users.metadata.total / users.metadata.pageSize));
    }
  }, [users]);


  const onPageChange = (page) => {
    if(page <= totalPages && page > 0){
      props.onSearchParamsChanged('page', page);
      props.onUserSearch();
    }
  };
  const debouncedUpdate = debounce(onPageChange, 1000);
  useEffect(() => {
    debouncedUpdate(paginationPage);
    // Cancel the debounce on useEffect cleanup.
    return () => {
      debouncedUpdate.cancel();
    };
  }, [paginationPage]);

  const onSortChange = (setSorting) => {
    const sorting = setSorting();
    props.onSearchParamsChanged('sortParam', sorting.length ? sorting[0].id : undefined);
    props.onSearchParamsChanged('sortDesc', !sorting.length ? undefined : sorting[0].desc);
    props.onSearchParamsChanged('sorting', sorting);

    props.onUserSearch();
  };

  const getSelected = () => {
    const selected = [];
    if(props.selectedUsers) {
      props.selectedUsers.forEach((f) => {
        const index = users.data.findIndex(x => x._id === f._id);
        selected.push(index);
      });
    }
    return selected;
  };

  const onPageSizeChange = (pageSize) => {
    props.onSearchParamsChanged('pageSize', pageSize);
    props.onSearchParamsChanged('page', page);

    props.onUserSearch();
  };

  // console.log('UsersTable', sorting, props, getSelected())
  return (
    <div>
      <Table columns={columns}
             data={users.data}
             isExpandable={true}
             checkable={true}
             onExpand={(row) => <UserDetail data={users.data.find(u => u.email === row.email)}/>}
             onSelect={props.onSelect}
             onSelectAll={props.onSelectAll}
             selectedRowIndex={getSelected()}
             useGlobalFilter={false}
             headerClassName="users-tbl-header"
             manualSorting={true}
             onSortChange={onSortChange}
             sorting={sorting}
             customPagination={(
               <div className="w-100 d-flex justify-content-center">
                 <div className="m-2">
                   Items found:<span>{users.metadata.total}</span>
                   (max page: <span>{totalPages}</span>)
                 </div>
                 <button className="form-control w-auto" style={{ height: '34px', fontSize: '0.9em' }}
                         onClick={(e) => {
                           e.preventDefault();
                           onPageChange(page - 1);
                         }}
                         disabled={page===1}
                 >&laquo;</button>
                 <input type="number"
                        style={{ height: '34px', fontSize: '0.9em' }}
                        className="form-control w-auto"
                        value={paginationPage}
                        min={1}
                        max={totalPages}
                        onChange={(e) => setPaginationPage(e.target.value * 1)}
                 />
                 <button className="form-control w-auto" style={{ height: '34px', fontSize: '0.9em' }}
                         onClick={(e) => {
                           e.preventDefault();
                           onPageChange(page+1);
                         }}
                         disabled={page===totalPages}
                 >&raquo;</button>
                 <select
                   className="form-select w-auto ms-1" style={{ height: '34px', fontSize: '0.9em' }}
                   value={pageSize}
                   onChange={(e) => onPageSizeChange(Number(e.target.value))}
                 >
                   <option value="10">Show 10</option>
                   <option value="50">Show 50</option>
                   <option value="100">Show 100</option>
                 </select>
               </div>)}
      />
    </div>
  );
};

UsersTable.propTypes = {
  users: PropTypes.object,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  selectedUsers: PropTypes.array,
  onSearchParamsChanged: PropTypes.func,
  onUserSearch: PropTypes.func,
  searchParams: PropTypes.object,
};

const withConnect = connect(null,
  dispatch => {
    return {
      onSearchParamsChanged: (param, value) => {
        dispatch({type: consts.SEARCH_PARAMS_CHANGED, param, value});
      },
    };
  },
);

export default compose(withConnect)(UsersTable);
