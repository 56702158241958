import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as homeSelectors from './selectors';
import * as globalSelectors from '../App/selectors';
import * as consts from '../App/constants';
import MultiSelect from 'components/MultiSelect';
import {ScaleSpinner} from 'components/Spinners';
import { validatePackages } from '../UserEditPage/packagesValidation';
import {arrayUnique} from 'utils/helperFunctions';
import moment from 'moment';

class BulkEditModal extends React.PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        updateWarehouses: false,
        updateLicenseExpiry: false,
        licenseExpiryVal: 0,
        updateUserType: false,
        userType: null,
        updateSka: false,
        isSka: null,
        removePackage: false,
        packageToRemove: '',
        warehouse: null,
        addPackage: false,
        newPackages: [],
        addFeature: false,
        newFeatures: []
      };

      this.onUpdateWareHouses = this.onUpdateWareHouses.bind(this);
      this.onUpdateLicenseExpiry = this.onUpdateLicenseExpiry.bind(this);
      this.onLicenseExpirySet = this.onLicenseExpirySet.bind(this);
      this.setPackageToRemove = this.setPackageToRemove.bind(this);
      this.onDeleteClick = this.onDeleteClick.bind(this);
      this.onSaveClick = this.onSaveClick.bind(this);
      this.onWarehouseChange = this.onWarehouseChange.bind(this);
      this.onRemovePackage = this.onRemovePackage.bind(this);
      this.onAddPackage = this.onAddPackage.bind(this);
      this.onPackagesChange = this.onPackagesChange.bind(this);
      this.onAddFeature = this.onAddFeature.bind(this);
      this.onFeaturesChange = this.onFeaturesChange.bind(this);
      this.onUpdateSka = this.onUpdateSka.bind(this);
      this.onSetSka = this.onSetSka.bind(this);
      this.onUpadteUserType = this.onUpdateUserType.bind(this);
      this.onSetUserType = this.onSetUserType.bind(this);
    }

    onUpdateWareHouses = () => this.setState({updateWarehouses: !this.state.updateWarehouses });
    onUpdateLicenseExpiry = () => this.setState({updateLicenseExpiry: !this.state.updateLicenseExpiry });
    onUpdateUserType = () => this.setState({updateUserType: !this.state.updateUserType });
    onSetUserType = (value) => this.setState({userType : value});
    onUpdateSka = () => this.setState({updateSka: !this.state.updateSka });
    onSetSka = (value) => this.setState({isSka: value});
    onRemovePackage = () => this.setState({removePackage: !this.state.removePackage });
    onLicenseExpirySet = (value) => this.setState({ licenseExpiryVal: value*1 });
    setPackageToRemove = (pack) => this.setState({ packageToRemove: pack });
    onWarehouseChange = (value) => this.setState({warehouse: value});
    onAddPackage = () => this.setState({addPackage: !this.state.addPackage });
    onPackagesChange = (value) => {
        if(value) {
          const arePackagesValid = validatePackages(this.state.newPackages, this.props.selectedUsers, value);
          if(arePackagesValid) {
            this.setState({ newPackages: value });
          }
        } else if(value === null) {
          this.setState({ newPackages: value });
        }
    };
    onAddFeature = () => this.setState({addFeature: !this.state.addFeature });
    onFeaturesChange = (value) => this.setState({newFeatures: value});

    onDeleteClick(){
        if(confirm('Are you sure you want to delete selected users?')){
            this.props.selectedUsers.forEach(u => {
                this.props.deleteUser(u.email.toLowerCase(), this.props.loggedInUser.email.toLowerCase());
            });
        }
    }

    onSaveClick(){
        this.props.selectedUsers.forEach(u => {
            let newUser = {...u};
            if(this.state.updateWarehouses){
                newUser = {...newUser, warehouse: this.state.warehouse.value};
            }
            if(this.state.updateUserType) {
                newUser = {...newUser, UserType: this.state.userType.value};
            }
            if(this.state.updateLicenseExpiry){
                let licenseExpiryDate = new Date();
                if(u.license.licenseExpires){
                    licenseExpiryDate = new Date(u.license.licenseExpires);
                }
                licenseExpiryDate.setDate(licenseExpiryDate.getDate() + this.state.licenseExpiryVal);

                newUser = {
                  ...newUser,
                  license: {
                    ...newUser.license,
                    licenseExpires: licenseExpiryDate
                  }
                };
            }
            if(this.state.updateSka) {
                newUser = {...newUser, ska: this.state.isSka};
            }
            if(this.state.removePackage){
                newUser = {
                  ...newUser,
                  license: {
                    ...newUser.license,
                    packages: newUser.license.packages.filter(p => p.name === this.state.packageToRemove)
                  }
                };
            }
            if(this.state.addPackage){
                newUser = {...newUser,
                  license: {...newUser.license,
                    packages: this.state.newPackages.map(p => { return { name: p.name, packageId: p.value };})
                  }
                };
            }
            if(this.state.addFeature){
                newUser = {
                  ...newUser,
                  license: {
                    ...newUser.license,
                    features: arrayUnique(newUser.license.features.concat(this.state.newFeatures.map(f => f.value)))
                  }
                };
            }
            if(this.state.updateWarehouses
                || this.state.updateUserType
                || this.state.updateLicenseExpiry
                || this.state.updateSka
                || this.state.removePackage
                || this.state.addPackage
                || this.state.addFeature){
                newUser.lastEditedBy = this.props.loggedInUser.email;
                newUser = {...newUser, license: {...newUser.license, editedOn: moment().valueOf(), editedBy: this.props.loggedInUser.email}};
                this.props.updateUser(newUser);
            }
        });
        this.props.searchUsers(this.props.searchParams);
    }

    render() {
        let {warehouse} = this.state;
        let warehouseLabel = warehouse ? warehouse.label: "Please select warehouse...";
        return (
					<div className="modal fade" id="BulkUpdateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="BulkUpdateModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
							<div className="modal-content">
								<div className="modal-header text-white" style={{background: "#046D7C"}}>
									<h5 className="modal-title" id="staticBackdropLabel">Bulk Edit SSP users (<b>{this.props.selectedUsers.length}</b> selected users)</h5>
									<button type="button" className="btn-close btn-light" data-bs-dismiss="modal" aria-label="Close"/>
								</div>

								<div className="modal-body">
									<div className="container-fluid">

										<div className="row" style={{alignItems:"center"}}>
                      <div className="col-3">
                        <div className="btn-group" style={{width: '100%'}} data-toggle="buttons">
														<input type="checkbox" className="btn-check" id="btn-check-warehouses" color="outline-secondary btn-sm"  onChange={this.onUpdateWareHouses} active={this.state.updateWarehouses.toString()} autoComplete="off"/>
														<label className="btn btn-outline-secondary" htmlFor="btn-check-warehouses">Warehouses</label>
												</div>
											</div>
                      <div className="col-9">
                        {this.props.warehouses && <MultiSelect isMulti={false} isDisabled={!this.state.updateWarehouses} controlStyles={{zIndex: "4"}}
                          data={this.props.warehouses.map(c => { return {value: c.shortDescription, label: `${c.shortDescription} - ${c.description}` };})}
                          onChange={(value) => this.onWarehouseChange(value)}
                          value={{value: warehouse, label: warehouseLabel }}/>}
                      </div>
                    </div>
                    <br/>

                    <div className="row">
                      <div className="col-3">
												<div className="btn-group" style={{width: '100%'}} data-toggle="buttons">
													<input type="checkbox" className="btn-check" id="btn-check-user-type" color="outline-secondary btn-sm"  onChange={this.onUpdateUserType} active={this.state.updateUserType.toString()} autoComplete="off"/>
													<label className="btn btn-outline-secondary" htmlFor="btn-check-user-type">User type</label>
												</div>
											</div>
											<div className="col-9">
												{this.props.userTypes && <MultiSelect isMulti={false} isDisabled={!this.state.updateUserType} controlStyles={{zIndex: "3"}}
													data={this.props.userTypes.map(c => {return {value: c, label: c };})}
													onChange={(value) => this.onSetUserType(value)}
													value={this.state.userType}/>}
											</div>
                    </div>
                    <br/>

                    <div className="row">
											<div className="col-3">
												<div className="btn-group" style={{width: '100%'}} data-toggle="buttons">
													<input type="checkbox" className="btn-check" id="btn-check-license-expire" color="outline-secondary btn-sm"  onChange={this.onUpdateLicenseExpiry} active={this.state.updateLicenseExpiry.toString()} autoComplete="off"/>
													<label className="btn btn-outline-secondary" htmlFor="btn-check-license-expire">License expiry</label>
												</div>
											</div>
											<div className="col-9" style={{display: "inline-flex"}}>
												<div className="btn-toolbar mb-3" role="toolbar" aria-label="Toolbar with radio butotns groups">
													<div className="btn-group me-2" role="group" aria-label="License expiry">
														<input type="radio" className="btn-check" name="LicenseExpiryOption" id="opt_90" autoComplete="off" value={90} onChange={(e) => this.onLicenseExpirySet(e.target.value)} checked={this.state.licenseExpiryVal === 90} disabled={!this.state.updateLicenseExpiry}/>
														<label className="btn btn-outline-secondary" htmlFor="opt_90">90</label>

														<input type="radio" className="btn-check" name="LicenseExpiryOption" id="opt_180" autoComplete="off" value={180} onChange={(e) => this.onLicenseExpirySet(e.target.value)} checked={this.state.licenseExpiryVal === 180} disabled={!this.state.updateLicenseExpiry}/>
														<label className="btn btn-outline-secondary" htmlFor="opt_180">180</label>

														<input type="radio" className="btn-check" name="LicenseExpiryOption" id="opt_365" autoComplete="off" value={365} onChange={(e) => this.onLicenseExpirySet(e.target.value)} checked={this.state.licenseExpiryVal === 365} disabled={!this.state.updateLicenseExpiry}/>
														<label className="btn btn-outline-secondary" htmlFor="opt_365">365</label>
													</div>
													<div className="input-group">
														<input type="number" className="form-control" min={-365} max={365} step={1} disabled={!this.state.updateLicenseExpiry} onChange={(e) => this.onLicenseExpirySet(e.target.value)} onKeyUp={(e) => (parseInt(e.target.value)>365) ? e.target.value = 365 : (parseInt(e.target.value)<-365) ? e.target.value = -365 : false} />
													</div>
												</div>
											</div>
                    </div>
                    <br/>

                    <div className="row">
											<div className="col-3">
												<div className="btn-group" style={{width: '100%'}} data-toggle="buttons">
													<input type="checkbox" className="btn-check" id="btn-check-ska" color="outline-secondary btn-sm"  onChange={this.onUpdateSka} active={this.state.updateSka.toString()} autoComplete="off"/>
													<label className="btn btn-outline-secondary" htmlFor="btn-check-ska">SKA</label>
												</div>
											</div>
											<div className="col-9" style={{display: "inline-flex"}}>
												<div className="btn-group" role="group" aria-label="SKA">
													<input type="radio" className="btn-check" name="SkaOption" id="opt_no" autoComplete="off" onChange={() => this.onSetSka(false)} checked={this.state.isSka === false} disabled={!this.state.updateSka}/>
													<label className="btn btn-outline-secondary" htmlFor="opt_no">No</label>

													<input type="radio" className="btn-check" name="SkaOption" id="opt_yes" autoComplete="off" onChange={() => this.onSetSka(true)} checked={this.state.isSka === true} disabled={!this.state.updateSka}/>
													<label className="btn btn-outline-secondary" htmlFor="opt_yes">Yes</label>
												</div>
											</div>
                    </div>
                    <br />

                    <div className="row">
											<div className="col-3">
												<div className="btn-group" style={{width: '100%'}} data-toggle="buttons">
													<input type="checkbox" className="btn-check" id="btn-check-remove-packages" color="outline-secondary btn-sm" onChange={this.onRemovePackage} active={this.state.removePackage.toString()} autoComplete="off"/>
													<label className="btn btn-outline-secondary" htmlFor="btn-check-remove-packages">Remove package(s)</label>
												</div>
											</div>
											<div className="col-9" style={{display: "inline-flex"}}>
												<div className="btn-group" role="group" aria-label="Remove packages(s)">
													<input type="radio" className="btn-check" name="removePackageOption" id="opt_eBiz" autoComplete="off" value="eBiz" onChange={(e) => this.setPackageToRemove(e.target.value)} checked={this.state.packageToRemove === "eBiz"} disabled={!this.state.removePackage}/>
													<label className="btn btn-outline-secondary" htmlFor="opt_eBiz">eBiz</label>

													<input type="radio" className="btn-check" name="removePackageOption" id="opt_Cascade" autoComplete="off" value="Cascade" onChange={(e) => this.setPackageToRemove(e.target.value)} checked={this.state.packageToRemove === "Cascade"} disabled={!this.state.removePackage}/>
													<label className="btn btn-outline-secondary" htmlFor="opt_Cascade">Cascade</label>

													<input type="radio" className="btn-check" name="removePackageOption" id="opt_Default" autoComplete="off" value="Default" onChange={(e) => this.setPackageToRemove(e.target.value)} checked={this.state.packageToRemove === "Default"} disabled={!this.state.removePackage}/>
													<label className="btn btn-outline-secondary" htmlFor="opt_Default">Default</label>

													<input type="radio" className="btn-check" name="removePackageOption" id="opt_Duals" autoComplete="off" value="Duals" onChange={(e) => this.setPackageToRemove(e.target.value)} checked={this.state.packageToRemove === "Duals"} disabled={!this.state.removePackage}/>
													<label className="btn btn-outline-secondary" htmlFor="opt_Duals">Duals</label>
												</div>
											</div>
										</div>
                    <br />

                    <div className="row">
											<div className="col-3">
												<div className="btn-group" style={{width: '100%'}} data-toggle="buttons">
													<input type="checkbox" className="btn-check" id="btn-check-set-packages" color="outline-secondary btn-sm"  onChange={this.onAddPackage} active={this.state.addPackage.toString()} autoComplete="off"/>
													<label className="btn btn-outline-secondary" htmlFor="btn-check-set-packages">Add package(s)</label>
												</div>
											</div>
											<div className="col-9">
												<ScaleSpinner loading={!this.props.packages} height={15} width={2} color="#6c757d" />
												{this.props.packages && <MultiSelect isMulti={true} isDisabled={!this.state.addPackage} data={this.props.packages.map(p => {return {value: p.packageId, label: p.name, name: p.name };})} onChange={(value) => this.onPackagesChange(value)} value={this.state.newPackages}/>}
											</div>
                    </div>
                    <br />

                    <div className="row">
											<div className="col-3">
												<div className="btn-group" style={{width: '100%'}} data-toggle="buttons">
													<input type="checkbox" className="btn-check" id="btn-check-set-features" color="outline-secondary btn-sm"  onChange={this.onAddFeature} active={this.state.addFeature.toString()} autoComplete="off"/>
													<label className="btn btn-outline-secondary" htmlFor="btn-check-set-features">Add feature(s)</label>
												</div>
											</div>
											<div className="col-9">
												<ScaleSpinner loading={!this.props.features} height={15} width={2} color="#6c757d" />
												{this.props.features && <MultiSelect isMulti={true} isDisabled={!this.state.addFeature} data={this.props.features.map(f => {return {value: f.text, label: `${f.text} - ${f.name}`, name: f.name };})} onChange={(value) => this.onFeaturesChange(value)} value={this.state.newFeatures}/>}
											</div>
                    </div>
									</div>
								</div>

								<div className="modal-footer">
									<a href={`mailto:${this.props.selectedUsers.map(x => x.email).join(';')}`} className="btn btn-info btn-sm me-2" onClick={this.onSendEmail}>Send Email</a>
                    <button type="button" className="btn btn-danger btn-sm me-2" data-bs-dismiss="modal" onClick={this.onDeleteClick}>Delete</button>
                    <button type="button" className="btn btn-secondary btn-sm me-2" data-bs-dismiss="modal" onClick={this.onSaveClick}>Save</button>
                    <button type="button" className="btn btn-light btn-sm me-2" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
								</div>
							</div>
						</div>
					</div>
        );
    }
}

BulkEditModal.propTypes = {
    selectedUsers: PropTypes.array,
    warehouses: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    deleteUser: PropTypes.func,
    updateUser: PropTypes.func,
    searchUsers: PropTypes.func,
    searchParams: PropTypes.object,
    loggedInUser: PropTypes.object,
    userTypes: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    packages: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    features: PropTypes.oneOfType([PropTypes.array, PropTypes.bool])
};

const mapStateToProps = createStructuredSelector({
    selectedUsers: homeSelectors.selectSelectedUsers(),
    warehouses: globalSelectors.makeSelectWarehouses(),
    userTypes: globalSelectors.makeSelectUsertypes(),
    searchParams: homeSelectors.selectSearchParams(),
    loggedInUser: globalSelectors.selectLoggedInUser(),
    packages: globalSelectors.makeSelectPackages(),
    features: globalSelectors.makeSelectFeatures()
});

const mapDispatchToProps = (dispatch) => {
    return {
      deleteUser: (email, deletedBy) => { dispatch({type: consts.SAGA_USER_DELETE, value:{email, deletedBy }}); },
      updateUser: user => dispatch({ type: consts.SAGA_USER_UPDATE, user }),
      searchUsers: searchParams => dispatch({ type: consts.LOAD_USERS, searchParams })
    };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(BulkEditModal);
