import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const DebouncedInput = (props) => {
  const {
    value: initialValue,
    onChange,
    debounce = 500,
  } = props;
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, onChange, debounce]);

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  );
};

DebouncedInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  debounce: PropTypes.number,
  className: PropTypes.string
};

export default DebouncedInput;
