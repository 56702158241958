import React from "react";
import PropTypes from "prop-types";

export const TblRadiobox = ({indeterminate, className = '', namePrefix, ...rest}) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="radio"
      name={`${namePrefix}_tbl-selection`}
      ref={ref}
      className={className + ' cursor-pointer'}
      {...rest}
    />
  );
};

TblRadiobox.propTypes = {
  indeterminate: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  namePrefix: PropTypes.string,
  className: PropTypes.string
};
