import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { makeSelectCountries } from '../../App/selectors';
import { connect } from 'react-redux';
import { SAGA_UPDATE_COUNTRY } from '../../App/constants';

const SalecContacts = (props) => {
  const [countriesState, setCountriesState] = useState([]);
  const {countries} = props;

  useEffect(() => {
    setCountriesState(countries);
  }, [countries]);

  const onChange = (country, salesContact) => {
    const cc = countriesState.map(c => {
      if (c.countryName === country) {
        c = {...c, salesContact};
      }
      return c;
    });
    setCountriesState(cc);
  };

  const onHandleChange = (country, value) => props.updateCountry(country, value);

  return (
    <div>
      <table className="table table-sm">
        <thead>
          <tr>
              <th className="w-25">Country</th>
              <th>Sales contact</th>
          </tr>
        </thead>
        <tbody>
        { countriesState && countriesState.map((c,i) => (
          <tr key={i}>
            <td>{c.countryName}</td>
            <td><input type="text" className="w-100" value={c.salesContact} onChange={(e) => onChange(c.countryName, e.target.value)} onBlur={(e) => onHandleChange(c.countryName, e.target.value)} /></td>
          </tr>
        ))
        }
        </tbody>
      </table>
    </div>
  );
};

SalecContacts.propTypes = {
  countries: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  updateCountry: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  countries: makeSelectCountries(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateCountry: (country, salesContact) => dispatch({type: SAGA_UPDATE_COUNTRY, country, salesContact})
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(SalecContacts);
