import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getExpandedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  createColumnHelper, flexRender
} from '@tanstack/react-table';
import {
  rankItem,
  // rankings,
} from '@tanstack/match-sorter-utils';

import {TblCheckbox} from "./TblCheckbox";
import './table.scss';
import ExpanderButton from "./ExpanderButton";
import {IconArrowDown, IconArrowUp} from "../icons/Icons";
import {TblRadiobox} from "./TblRadiobox";
import clsx from "clsx";
import DebouncedInput from "./DebouncedInput";

const fuzzyFilter= (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const columnHelper = createColumnHelper();

const LMTable = (props) => {
  const [rowSelection, setRowSelection] = useState({});
  const [expanded, setExpanded] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: props.pageSize,
  });
  const [globalFilter, setGlobalFilter] = useState('');
  const [uiSorting, setUiSorting] = useState();

  const {columns, checkable, isExpandable, onSelect, onSelectAll, radioNamePrefix, selectionType, sorting} = props;

  const tblColumns = useMemo(() => {
    const checkboxColumn = {
      id: 'select',
      size: 20,
      header: ({ table }) => (
        <div>
          <TblCheckbox
            {...{
              className: 'tbl-checkbox',
              // checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: (e) => {
                table.toggleAllRowsSelected();
                onSelectAll(e.target.checked, table.getRowModel().rows.map(r => r.original));
              },
            }}
          />
        </div>
      ),
      cell: ({ row}) => (
        <div className="">
          {selectionType === 'single' && (
            <TblRadiobox namePrefix={radioNamePrefix}
              {...{
                className: 'tbl-radiobox',
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: () => {
                  row.getToggleSelectedHandler();
                  row.getIsSelected();
                    setRowSelection({[row.id]: true});
                  onSelect(row.original);
                },
              }}
            />
          ) || (
            <TblCheckbox
              {...{
                className: 'tbl-checkbox',
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: (e) => {
                  row.getToggleSelectedHandler();
                  setRowSelection({[row.id]: e.target.checked});
                  onSelect(row.original, e.target.checked);
                },
              }}
            />
          )}
        </div>
      ),
    };

    const tblColumns = columns.map((col) => {
      const column = columnHelper.accessor(col.accessor || col.key, {
        id: col.key,
        header: col.headerRenderer,
        cell: (instance) => col.cellRenderer(instance.row.original),
        enableGlobalFilter: true,
        size: col.size,
      });
      return column;
    });

    if(checkable){
      tblColumns.unshift(checkboxColumn);
    }

    if(isExpandable){
      const expandColumn = {
        id: 'select',
        header: null,
        size: 20,
        cell: ({ row }) => (
          <div className="px-1">
            <ExpanderButton isExpanded={row.getIsExpanded()}
                            onExpand={() => {
                              if(isExpandable) {
                                row.toggleExpanded();
                              }
                            }} />
          </div>
        ),
      };
      tblColumns.unshift(expandColumn);
    }

    return tblColumns;
  }, [columns, checkable, isExpandable, onSelect, onSelectAll, radioNamePrefix, selectionType]);

  useEffect(() => {
    if(props.selectedRowIndex && props.selectedRowIndex.length >= 0) {
      const selected = {};
      props.selectedRowIndex.forEach(r => {
        selected[r] = true;
      });
      setRowSelection(selected);
    }
  }, [props.selectedRowIndex]);

  const table = useReactTable({
    data: props.data,
    columns: tblColumns,
    defaultColumn: {
      width: 'auto',
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      rowSelection,
      expanded,
      pagination,
      globalFilter,
      sorting: props.manualSorting? sorting: uiSorting
    },
    manualSorting: props.manualSorting,
    onRowSelectionChange: setRowSelection,
    onSortingChange: props.manualSorting ? props.onSortChange: setUiSorting,
    onExpandedChange: setExpanded,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const onRowClick = (row) => {
    if(props.isRowSelectable) {
      row.getToggleSelectedHandler();
      row.getIsSelected();
      setRowSelection({[row.id]: true});
      props.onSelect(row.original, true);
    }
  };

  const getPages = () => {
    const pages = [];
    for(let i = 0; i < table.getPageCount(); i++){
      pages.push(i+1);
    }
    return pages;
  };

  // console.log('%cDTTable', 'color: lightblue', props, sorting);
  return (
    <div className="licenseman-tbl table-responsive-xl">
      <table className="table table-hover table-sm"
         {...{
           style: {
             width: '100%'
           },
         }}
      >
        <thead className={props.headerClassName}>
          <tr>
            {table.getFlatHeaders().map((header, index) => (
              <th key={index} colSpan={header.colSpan}
                  {...{
                    style: {
                      width: header.getSize(),
                    },
                  }}
              >
                {header.isPlaceholder ? null : (
                  <div className="d-flex" >
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? 'cursor-pointer select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: <IconArrowUp />,
                        desc: <IconArrowDown />,
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  </div>
                )}
              </th>
            ))}
          </tr>
          {props.useGlobalFilter && (<tr>
              <th
              colSpan={"100%"}
              style={{
                textAlign: 'left',
              }}
            >
              <DebouncedInput
                value={globalFilter ?? ''}
                onChange={value => setGlobalFilter(String(value))}
                className="w-100 font-lg shadow border border-block"
                placeholder="Search all columns..."
              />
            </th>
          </tr>)
          }
        </thead>
        <tbody>
        {table.getRowModel().rows.map((row, index) => (
          <React.Fragment key={index}>
            <tr data-row-selected={rowSelection[row.id]}
                onClick={() => onRowClick(row)}
            >
              {row.getVisibleCells().map((cell, index) => {
               return (
                <td key={index} style={{verticalAlign: 'middle'}}>
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                  )}
                </td>
              );}
             )}
            </tr>
            {row.getIsExpanded() && (<tr><td colSpan="100%">{props.onExpand(row.original)}</td></tr>)}
          </React.Fragment>
        ))}
        </tbody>
      </table>
      {props.customPagination && (props.customPagination) || (
      <div className="d-flex justify-content-md-center">
        <ul className="pagination">
          <li className={clsx("page-item", {"disabled": !table.getCanPreviousPage()})}>
            <a className="page-link" href="#" aria-label="Previous"
               onClick={() => {
                 table.previousPage();
            }}>
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          {
            getPages().map((p) => {
              return (
                <li key={p}
                    className={clsx("page-item", {"active" : table.getState().pagination.pageIndex + 1 === p})}
                    onClick={() => {
                      table.setPageIndex(p-1);
                    }}
                ><a className="page-link" href="#">{p}</a></li>
              );
            })
          }
          <li className={clsx("page-item", {"disabled": !table.getCanNextPage()})}>
            <a className="page-link"
               href="#" aria-label="Next"
               onClick={() => {
                 table.setPageIndex(table.getPageCount() - 1);
            }}>
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
        <select
          className="form-select w-auto ms-1" style={{height: "34px", fontSize: "0.9em"}}
          value={table.getState().pagination.pageSize}
          onChange={e => table.setPageSize(Number(e.target.value))}
        >
          {props.pageSizes.map(p => (
            <option key={p} value={p}>
              Show {p}
            </option>
          ))}
        </select>
      </div>
      )}
    </div>
  );
};

LMTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  isRowSelectable: PropTypes.bool,
  checkable: PropTypes.bool,
  onChecked: PropTypes.func,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  headerClassName: PropTypes.string,
  selectedRowIndex: PropTypes.array,
  isExpandable: PropTypes.bool,
  onExpand: PropTypes.func,
  radioNamePrefix: PropTypes.string,
  selectionType: PropTypes.string,
  pageSizes: PropTypes.array,
  pageSize: PropTypes.number,
  useGlobalFilter: PropTypes.bool,
  customPagination: PropTypes.object,
  onSortChange: PropTypes.func,
  sorting: PropTypes.array,
  manualSorting: PropTypes.bool,
};

LMTable.defaultProps = {
  isRowSelectable: false,
  pageSizes: [10, 50, 100],
  pageSize: 50,
  useGlobalFilter: true
};

export default LMTable;
