import React, {useState} from 'react';
import PropTypes from 'prop-types';


const TempPasswordButton = ({ onSetTemporaryPassword, email }) => {
    const [tempPassword, setTempPassword] = useState('');

    const setTempPwd = () => {
        onSetTemporaryPassword(email, tempPassword);

    };

    return (
        <span>
					<button type="button" className="btn btn-outline-warning btn-sm me-2" data-bs-toggle="modal" data-bs-target="#TempPasswordModal">Set temp. password</button>      
					<div className="modal fade" id="TempPasswordModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="TempPasswordModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm">
							<div className="modal-content">
								<div className="modal-header text-white bg-warning">
									<h5 className="modal-title" id="staticBackdropLabel">Set temporary password on user&apos;s account</h5>
									<button type="button" className="btn-close btn-light" data-bs-dismiss="modal" aria-label="Close"/>
								</div>

								<div className="modal-body">
									<div className="container-fluid">
										<div className="alert alert-warning temp-pwd-note" role="alert">Temporary password is valid only for 5 minutes!</div>
                    <div className="alert alert-warning temp-pwd-note" role="alert">User&apos;s password will not be affected by that</div>
                    <input type="text" className="form-control temp-pwd-tb" placeholder="Temporary password (min 6 chars)" onChange={(e) => setTempPassword(e.target.value)} />
									</div>
								</div>

								<div className="modal-footer">
									<button type="button" className="btn btn-warning float-end btn-sm me-2" data-bs-dismiss="modal" disabled={(tempPassword.length < 6)} onClick={setTempPwd}>Submit</button>
									<button type="button" className="btn btn-light btn-sm me-2" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
								</div>
							</div>
						</div>
					</div>
        </span>
    );
};

TempPasswordButton.propTypes = {
    onSetTemporaryPassword: PropTypes.func,
    email: PropTypes.string
};

export default TempPasswordButton;

