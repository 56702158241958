import React from 'react';
import { withBaseIcon } from 'react-icons-kit';
import {filter} from 'react-icons-kit/fa/';
import {ic_check, ic_close, ic_add, ic_remove, ic_add_circle_outline } from 'react-icons-kit/md/';
import {smallDown} from 'react-icons-kit/entypo/smallDown';
import {smallUp} from 'react-icons-kit/entypo/smallUp';
import {ic_keyboard_arrow_down} from 'react-icons-kit/md/ic_keyboard_arrow_down';
import {ic_keyboard_arrow_up} from 'react-icons-kit/md/ic_keyboard_arrow_up';

//check https://github.com/wmira/react-icons-kit for icons

const SideIconContainer = withBaseIcon({ style: {verticalAlign: 'bottom'}});

export const FilterIcon = (props) => <SideIconContainer icon={filter} {...props} />; // eslint-disable-line
export const ActiveIcon = (props) => <SideIconContainer icon={ic_check} {...props} />; // eslint-disable-line
export const InactiveIcon = (props) => <SideIconContainer icon={ic_close} {...props} />; // eslint-disable-line
export const PlusIcon = (props) => <SideIconContainer icon={ic_add} {...props} />; // eslint-disable-line
export const MinusIcon = (props) => <SideIconContainer icon={ic_remove} {...props} />; // eslint-disable-line
export const AddOutlineIcon = (props) => <SideIconContainer icon={ic_add_circle_outline} {...props} />; // eslint-disable-line
export const SmallUp = (props) => <SideIconContainer icon={smallUp} {...props} />; // eslint-disable-line
export const SmallDown = (props) => <SideIconContainer icon={smallDown} {...props} />; // eslint-disable-line
export const IconArrowDown = (props) => <SideIconContainer icon={ic_keyboard_arrow_down} {...props} />; // eslint-disable-line
export const IconArrowUp = (props) => <SideIconContainer icon={ic_keyboard_arrow_up} {...props} />; // eslint-disable-line
