import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectPressureDropTool = state => state.notifier || initialState;

const makeSelectNotifications = () =>
  createSelector(
    selectPressureDropTool,
    state => state.notifications
  );

export {
  makeSelectNotifications
};
