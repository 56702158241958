import 'whatwg-fetch';
import axios from 'axios';

const isDevelopment = () => process.env.NODE_ENV === 'development';

const getWithCredentialsForNodeEnv = () => {
  return isDevelopment();
};

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.data;
  // return response.json();
}

export function postRequest(url, bodyData, requestHeaders){
  return axios({
    method: 'POST',
    url: url,
    headers: requestHeaders ? requestHeaders : { 'content-type': 'application/json' },
    data: bodyData,
  });
}

export function putRequest(url, bodyData){
  return axios({
    method: 'PUT',
    url: url,
    headers: { 'content-type': 'application/json' },
    data: bodyData
  });
}

export function deleteRequest(url, bodyData){
  return axios({ method: 'DELETE',
    url: url,
    headers: { 'content-type': 'application/json' },
    data: bodyData
  });
}

// important when having node js on different port than web api
axios.defaults.withCredentials = getWithCredentialsForNodeEnv();


axios.interceptors.response.use(response => {
  return response;
}, error => {
  const { status } = error.response;
  if (status === 401 || status === 403) {
    window.location.href = `${process.env.API}MicrosoftIdentity/account/signin`;
  }

    //const isOnline = function(session) {
    //  const currentTime = (new Date()).getTime() / 1000;
    //  return session && session.access_token && session.expires > currentTime;
    //};


    //let isUserActive = isOnline(authService.getAuthResponse());
    //if (status === 403 || !isUserActive) {
    //  history.push('/notauthorized');
    //}
    //if (status === 401 || !isUserActive) {
    //  authService.login(null, true, () => history.push('/notauthorized'));
    //}
    //return Promise.reject(error);
});


export default function request(url) {
  return axios({
    method: 'GET',
      url: url,
      // withCredentials: true
    }).then(parseJSON);
}
