import React from 'react';
import PropTypes from 'prop-types';
import logo from '../images/licenseman_logo.png';
import styled from 'styled-components';
import { NavLink, withRouter } from 'react-router-dom';

const NLink = styled(NavLink)`
  text-decoration: none;
  padding-left: 5px;
  padding-right: 5px;
  color: darkslategrey;
  font-weight: 600;
`;

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const selectedStyle = {
      textDecoration: 'underline',
      color: '#046d80',
    };

    return (
      <div>
       <nav className="navbar navbar-expand-md navbar-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="/"><img src={logo} style={{ height: '30px' }} /></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
                  aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NLink
                  to="/"
                  style={
                    this.props.location.pathname === '/' ? selectedStyle : {}
                  }
                >SSP Users</NLink>
              </li>
              {this.props.loggedUser &&
                this.props.loggedUser.isAdmin && (
                  <li className="nav-item">
                    <NLink
                      to="/packagesandfeatures"
                      style={
                        this.props.location.pathname === '/packagesandfeatures'
                          ? selectedStyle
                          : {}
                      }
                    >Packages & Features</NLink>
                  </li>
              )}
              {this.props.loggedUser &&
              this.props.loggedUser.isAdmin && (
                <NLink
                  to="/powerusers"
                  style={
                    this.props.location.pathname === '/powerusers'
                      ? selectedStyle
                      : {}
                  }
                >Powerusers</NLink>
              )}
              {this.props.loggedUser &&
              this.props.loggedUser.isAdmin && (
                <NLink
                  to="/xrefadmin"
                  style={
                    this.props.location.pathname === '/xrefadmin'
                      ? selectedStyle
                      : {}
                  }
                >XRef admin</NLink>
              )}
              {this.props.loggedUser &&
              this.props.loggedUser.isAdmin && (
                <NLink
                  to="/dthermXAdmin"
                  style={
                    this.props.location.pathname === '/dthermXAdmin'
                      ? selectedStyle
                      : {}
                  }
                >DThermX admin</NLink>
              )}
              {this.props.loggedUser &&
              this.props.loggedUser.isAdmin && (
                <NLink
                  to="/customizedAdmin"
                  style={
                    this.props.location.pathname === '/customizedAdmin'
                      ? selectedStyle
                      : {}
                  }
                >Customized admin</NLink>
              )}
              {this.props.loggedUser &&
                this.props.loggedUser.isAdmin && (
                  <NLink
                    to="/apiAdmin"
                    style={
                      this.props.location.pathname === '/apiAdmin'
                        ? selectedStyle
                        : {}
                    }
                  >API admin</NLink>
                )}
              <NLink
                to="/userinfo"
                style={
                  this.props.location.pathname === '/userinfo'
                    ? selectedStyle
                    : {}
                }
              >User info</NLink>
            </ul>
          </div>
        </div>
      </nav>
      </div>
    );
  }
}

Header.propTypes = {
  loggedUser: PropTypes.object,
  location: PropTypes.object
};

export default withRouter(Header);
