export const GET_COUNTRIES = 'constants/countries';
export const GET_FEATURES = 'features';
export const GET_PACKAGES = 'packages';
export const CREATEUPDATE_PACKAGES = 'packages';
export const FEATURES_GETPACKAGES = 'packages';
export const CREATEUPDATEDEL_FEATURES = 'features';
export const GET_USER_INFO = 'user/userinfo';
export const GET_WAREHOUSES = 'constants/warehouses';
export const GET_USERTYPES = 'constants/usertypes';

export const SEARCH_USERS = 'user/search';
export const UPDATE_USER = 'profile/addorupdate';
export const USER_SEND_VERIFICATION_MAIL = 'users/sendverificationemail';
export const USER_SEND_VERIFICATION_FOR_FOOTPRINT = 'profile/sendfootprintverification';
export const USER_SEND_VERIFICATION_FOR_DTHERMX = 'profile/senddthermxverification';
export const USER_SET_TEMP_PWD = 'profile/temppassword';
export const USER_DOWNLOAD_LICENSE_FILE = 'users/downloadlicensefile';
export const USER_SEND_LICENSE_FILE = 'users/sendlicensefile';
export const USER_CHECK_NEW_USER = 'users/checknewuser';
export const USER_DELETE = 'profile/delete';
export const USER_EMAILS_LOGS = 'profile/emailslogs';
export const USER_COUNT_BY_FEATURE = 'users/countbyfeature';
export const USER_COUNT_BY_PACKAGE = 'users/countbypackage';

export const POWERUSERS_GETALL = 'powerusers/all';
export const POWERUSERS_POWERUSER = 'powerusers/poweruser';
export const POWERUSER_GETBY_EMAIL = 'powerusers/poweruserbyemail';

//cross reference tool
export const XREF_CONFIGS_LOAD = 'xref/getconfigurations';
export const XREF_HXS_TYPES = 'xref/exchangerstypes';
export const XREF_HXS_SIZES = 'xref/exchangerssizesbytype';
export const XREF_HXS_SUBTYPES = 'xref/exchangerssubtypes';
export const XREF_UPDATE_ALTERNATIVES = 'xref/updatealternatives';
export const XREF_CONFIG = 'xref/config';
export const XREF_GET_MANUFACTURERS = 'xref/getcompetitormanufacturers';
export const XREF_PRODUCTS_BY_MANUFACTURER = 'xref/getproductsbymanufacturer';
export const XREF_XPRODUCTS = 'xref/xproduct';
export const XREF_STATS_REQUESTS = 'xref/statistics/requestsbydate';
