import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

function MultiSelect(props){
    let customStyles = {
      control: styles => ({ ...styles, 
        fontSize:'.875rem',
        lineHeight: '1.5',
        boxShadow: '#6c757d',
        borderColor: '#6c757d',
        ...props.styles
      }),
      container: (options) => ({...options,
        ...props.controlStyles
      }),
    };

    return (
      <Select styles={customStyles} isDisabled={props.isDisabled} 
        isMulti={props.isMulti}
        options={props.data} 
        onChange={props.onChange}
        value={props.value}/>
    );
}

MultiSelect.propTypes = {
  data: PropTypes.array,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  isDisabled: PropTypes.bool,
  styles: PropTypes.object,
  controlStyles: PropTypes.object,
};

export default MultiSelect;
