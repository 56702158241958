import { produce } from 'immer';
import * as consts from './../App/constants';

// The initial state of the App
export const initialState = {
  username: '',
  users: false,
  searchLoading:false,
  searchParams: {
    searchActive: true,
    skaFilter: "Any",
    licTypeFilter: "Any",
    featureNameFilter: "Any",
    swepFilter: "Any",
    userTypeFilter: "Any",
    countryFilter: "Any",
    isActiveFilter: "Both",
    demoLicences: false,
    expiredLicenses: false,
    searchString: '',
    licenseDays: '',
    page: 1,
    pageSize: 50
  },
  selectedUser: {},
  selectedUsers: []
};

const homeReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case consts.CHANGE_USERNAME:
        // Delete prefixed '@' from the github username
        draft.username = action.name.replace(/@/gi, '');
        break;
      case consts.LOAD_USERS:
        draft.users = false;
        draft.searchLoading = true;
        break;
      case consts.USERS_LOADED:
        draft.searchLoading = false;
        draft.users =  action.value;
        break;
      case consts.SEARCH_PARAMS_CHANGED:
        draft.searchParams[action.param] = action.value;
        break;
      case consts.RESET_SEARCH:
        draft.searchParams = initialState.searchParams;
        break;
      case consts.SELECT_USER_FOR_EDIT:
        draft.selectedUser = action.user;
        break;
      case consts.USER_SELECT: {
        let su = [...state.selectedUsers];
          if(su.some(u => u.userId === action.value.userId)){
            su = su.filter(u => u.userId !== action.value.userId);
          } else {
            su.push(action.value);
          }
        draft.selectedUsers = su;
        break;
      }
      case consts.USER_DELETED:{
        draft.selectedUser = {};
        let sus = state.selectedUsers;
        sus = sus.filter(u => u.userId !== action.value);
        draft.selectedUsers = sus;
        let us = state.users.data;
        us = us.filter(u => u.userId !== action.value);
        draft.users.data = us;
        break;
      }
      case consts.USER_SELECT_ALL:{
        if(action.value.isAllSelected){
          draft.selectedUsers = action.value.users;
        } else {
          draft.selectedUsers = [];
        }
        break;
      }
      case consts.USER_UPDATED:{
        let newSelectedUsers = state.selectedUsers.map(x => {
          if(x.userId === action.value.userId){
            x = action.value;
          }
          return x;
        });
        draft.selectedUsers = newSelectedUsers;

        if(state.users){
          draft.users.data[state.users.data.findIndex(x => x.userId === action.value.userId)] = action.value;
        }

        draft.selectedUser = action.value;
        break;
      }
      case consts.USER_CLEAR_SELECTED_USERS:
        draft.selectedUsers = [];
        break;
      default:
        return state;
    }
  });

export default homeReducer;
