import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import {
  makeSelectLoading, makeSelectError, makeSelectUsertypes,
  makeSelectCountries, makeSelectFeatures, makeSelectPackages
} from 'containers/App/selectors';
import LoadingIndicator from 'components/LoadingIndicator';
import List from 'components/List';
import { makeSelectUsername, selectUsersLoading,
      selectFoundUsers, selectSearchParams, selectSelectedUsers } from './selectors';
import reducer from './reducer';
import saga from './saga';
import userSaga from '../UserEditPage/saga';
import SearchFilter from './SearchFilter';
import * as consts from '../App/constants';
import BulkEditModal from './BulkEditModal';
import './home.scss';
import UsersTable from "./UsersTable";

const HomePage = (props) => {
  const [triggerSearch, setTriggerSearch] = useState(false);

  const {searchParams, countries, features, packages, searchedUsers, onGetMe, username, onSubmitForm, onSearchUsers} = props;

  useEffect(() => {
    if (username && username.trim().length > 0) {
      onSubmitForm();
    }
    if(localStorage.hello){
      onGetMe(() => {});
    }
  }, [username, onGetMe, onSubmitForm]);

  useEffect(() => {
    if(triggerSearch){
      setTriggerSearch(false);
      onSearchUsers(searchParams);
    }
  }, [searchParams, onSearchUsers, triggerSearch]);

  const onLicenseUpload = (licenseXml) => {
    let redirectToUserEdit = () => props.history.push('/useredit');
    props.onLicenseFileLoad(licenseXml, redirectToUserEdit);
  };

  const onUserSearch = () => {
    setTriggerSearch(true);
  };

  // console.log('SSP Users', this.props)
  return (
    <article>
      <Helmet>
        <title>SSP Users</title>
        <meta
          name="description"
          content="SSP Users search"
        />
      </Helmet>
      <div>
        <form>
          <SearchFilter countries={countries} features={features} packages={packages}
                        userTypes={props.userTypes} onUserSearch={onSearchUsers}
            searchParams={searchParams}
            onSearchParamsChanged={props.onSearchParamsChanged}
            onResetSearch={props.onResetSearch}
            onLicenseUpload={onLicenseUpload}/>
          {props.usersLoading && <List component={LoadingIndicator} />}
          {searchedUsers && (
            <UsersTable
              users={searchedUsers}
              selectedUsers={props.selectedUsers}
              onSelect={props.onSelectUser}
              onSelectAll={props.onSelectAllUsers}
              onUserSearch={onUserSearch}
              // searchParams={searchParams}
            />)}
          {/*{this.props.searchedUsers && <UsersTable users={searchedUsers} selectedUsers={this.props.selectedUsers} onSelect={this.props.onSelectUser} onSelectAll={this.props.onSelectAllUsers} />}*/}
        </form>
      </div>
      <BulkEditModal />
    </article>
  );
};

HomePage.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  countries: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  features: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  packages: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  usersLoading: PropTypes.bool,
  searchedUsers: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onSubmitForm: PropTypes.func,
  username: PropTypes.string,
  onSearchUsers: PropTypes.func,
  onGetMe: PropTypes.func,
  history: PropTypes.object,
  onLicenseFileLoad: PropTypes.func,
  userTypes: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  searchParams: PropTypes.object,
  onSearchParamsChanged: PropTypes.func,
  onResetSearch: PropTypes.func,
  selectedUsers: PropTypes.array,
  onSelectUser: PropTypes.func,
  onSelectAllUsers: PropTypes.func
};

export function mapDispatchToProps(dispatch) {
  return {
    onSearchUsers: searchParams => {
      if (searchParams !== undefined && searchParams.preventDefault) searchParams.preventDefault();
      dispatch({ type: consts.LOAD_USERS, searchParams });
    },
    onSearchParamsChanged: (param, value) => {
      dispatch({type: consts.SEARCH_PARAMS_CHANGED, param, value});
    },
    onResetSearch: () => { dispatch({type: consts.RESET_SEARCH }); },
    onSelectUser: (item, iSelected) => { dispatch({type: consts.USER_SELECT, value: item, iSelected }); },
    onSelectAllUsers: (isAllSelected, users) => { dispatch({type: consts.USER_SELECT_ALL, value: { isAllSelected, users} }); },
    onLicenseFileLoad: (licenseXml, callback) => { dispatch({type: consts.SAGA_USER_LOAD_LICENSE_FILE, value: { licenseXml, callback} }); },
    onGetMe: (callback) => { dispatch({type: consts.SAGA_LOAD_ME, value: { callback } }); },
  };
}

const mapStateToProps = createStructuredSelector({
  username: makeSelectUsername(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  countries: makeSelectCountries(),
  features: makeSelectFeatures(),
  packages: makeSelectPackages(),
  userTypes: makeSelectUsertypes(),
  usersLoading: selectUsersLoading(),
  searchedUsers: selectFoundUsers(),
  searchParams: selectSearchParams(),
  selectedUsers: selectSelectedUsers(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'home', reducer });
const withSaga = injectSaga({ key: 'home', saga });
const withUserEditSaga = injectSaga({ key: 'useredit', saga: userSaga });

export default compose(
  withReducer,
  withSaga,
  withUserEditSaga,
  withConnect,
  withRouter
)(HomePage);
