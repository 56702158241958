import React from 'react';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import reducer from '../reducer';
import saga from '../saga';
import { compose } from 'redux';
import '../xrefstyle.scss';
import SalecContacts from './SalesContacts';
import XRefConfigureCountries from './XRefConfigureCountries';

const CountriesConfig = () => {
  return (
    <div className="container-fluid">
      <h3 className="page-title">Countries configuration</h3>
      <div className="d-flex align-items-start">
        <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <a className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home"
                  type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Sales contacts
          </a>
          <a className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile"
                  type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">X-Reference configuration</a>
        </div>
        <div className="tab-content w-100" id="v-pills-tabContent">
          <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
               aria-labelledby="v-pills-home-tab">
            <SalecContacts />
          </div>
          <div className="tab-pane fade" id="v-pills-profile" role="tabpanel"
               aria-labelledby="v-pills-profile-tab">
            <XRefConfigureCountries />
          </div>
        </div>
      </div>
    </div>
  );
};

CountriesConfig.propTypes = {

};

const withReducer = injectReducer({ key: 'xref', reducer });
const withSaga = injectSaga({ key: 'xrefSaga', saga });

export default compose(withReducer, withSaga)(CountriesConfig);
