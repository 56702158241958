import toastr from 'toastr';

export function validatePackages(existingPackages, users, newPackages) {
  let isValid = false;

  newPackages.forEach(
    p => (isValid = validatePackage(p, null, existingPackages)),
  );
  isValid = !users.some(u => {
    return newPackages.some(p => {
      return !validateSwepInternalPackages(u, p);
    });
  });

  return isValid;
}

export function validatePackage(newPackage, user, existingPackages) {
  let userPackages = [];
  if (existingPackages) {
    userPackages = existingPackages;
  } else {
    userPackages = user.license.packages || [];
  }
  const lowPackages = ['Demo', 'Default', 'Cascade', 'Duals', 'eBiz'];
  const highPackages = ['SWEP Internal', 'Superuser'];
  if (
    highPackages.some(x => x === newPackage.name) &&
    userPackages.some(p => lowPackages.some(x => x === p.name))
  ) {
    toastr.warning(
      `'${newPackage.name}' cannot be combined with ${lowPackages.map(x => x)}`,
      'Package validation',
    );
    return false;
  }
  if (
    lowPackages.some(x => x === newPackage.name) &&
    userPackages.some(p => highPackages.some(x => x === p.name))
  ) {
    toastr.warning(
      `'${newPackage.name}' cannot be combined with ${highPackages.map(
        x => x,
      )}`,
      'Package validation',
    );
    return false;
  }
  if (user) {
    return validateSwepInternalPackages(user, newPackage);
  }
  if (
    highPackages.some(x => x === newPackage.name) &&
    userPackages.some(p => highPackages.some(x => x === p.name))
  ) {
    toastr.warning(
      `'${newPackage.name}' cannot be combined with ${highPackages.map(
        x => x,
      )}`,
      'Package validation',
    );
    return false;
  }
  if (
    newPackage.name === 'Superuser' &&
    userPackages.find(x => x.name !== 'R&D') &&
    userPackages.length > 0
  ) {
    toastr.warning(
      `'Superuser' can be combined only with R&D`,
      'Package validation',
    );
    return false;
  }
  if (
    newPackage.name !== 'R&D' &&
    userPackages.find(x => x.name === 'Superuser')
  ) {
    toastr.warning(
      `'Super user' can be combined only with R&D2`,
      'Package validation',
    );
    return false;
  }

  return true;
}

function validateSwepInternalPackages(user, newPackage) {
  const swepOnlyPackages = ['SWEP Internal', 'Superuser', 'R&D'];

  if (
    swepOnlyPackages.some(x => x === newPackage.name) &&
    user.email.indexOf('@swep.net', user.email.length - '@swep.net'.length) ===
      -1
  ) {
    toastr.warning(
      `For '${
        newPackage.name
      }' user must be a SWEP employee. Email must be in ***@swep.net format`,
      'Package validation',
    );
    return false;
  }
  return true;
}
