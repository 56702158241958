import React from 'react';
import PropTypes from 'prop-types';

const UserInfoDetails = ({ userInfo }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6">
          <label className="control-label">Username:</label>
          <span>{userInfo.Username}</span>
        </div>
        <div className="col-sm-6">
          <label className="control-label">Email: </label>
          <span>{userInfo.Email}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <label className="control-label">User Type: </label>
          <span>{userInfo.UserType}</span>
        </div>
        <div className="col-sm-6">
          <label className="control-label">Ifs Id:</label>
          <span>{userInfo.IfsId}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <label className="control-label">Created On: </label>
          <span>{userInfo.CreatedOn.replace('T', ' ').replace('Z', '')}</span>
        </div>
        <div className="col-sm-6">
          <label className="control-label">Last Changed:</label>
          <span>{userInfo.LastChanged.replace('T', ' ').replace('Z', '')}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <label className="control-label">License Expires:</label>
          <span>
            {userInfo.LicenseExpires.replace('T', ' ').replace('Z', '')}
          </span>
        </div>
      </div>
    </div>
  );
};

UserInfoDetails.propTypes = {
  userInfo: PropTypes.object.isRequired,
};

export default UserInfoDetails;
