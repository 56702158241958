import React from "react";
import PropTypes from 'prop-types';
import IconButton from "@material-ui/core/IconButton";
import {MinusIcon, PlusIcon} from "../icons/Icons";

const ExpanderButton = ({ isExpanded, onExpand }) => {
  return (
    <IconButton size="small" aria-label="expand" onClick={onExpand}>
      {isExpanded ? <MinusIcon/> : <PlusIcon/>}
    </IconButton>
  );
};

ExpanderButton.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired
};

export default React.memo(ExpanderButton);
