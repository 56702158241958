import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import {FilterIcon} from '../../components/icons/Icons';
import MultiSelect from 'components/MultiSelect';
import {ScaleSpinner} from 'components/Spinners';

const SearchFilter = (props) => {
    const [collapseFilter, setCollapseFilter] = useState(true);
    const [appFilter, setAppFilter] = useState('BOTH');
    const [verifiedFilter, setVerifiedFilter] = useState(null);
    let uploadInput = null;

    const toggleFilterCollapse = () => setCollapseFilter(!collapseFilter);

    const toggleActiveUsers = () => {
        onSearchParamsChange('searchActive', !searchParams.searchActive);
    };

    const selectDropDown = (ddl, event) => {
        onSearchParamsChange([ddl], event.target.innerText);
    };

    const checkDemoLicenses = () => {
        onSearchParamsChange('demoLicences', !searchParams.demoLicences);
    };
    const checkExpiredLicenses = () => {
        onSearchParamsChange('expiredLicenses', !searchParams.expiredLicenses);
    };

    const onFormsChange = (e) => {
        onSearchParamsChange([e.target.name], e.target.value);
    };

    const onMultiSelChange = (searchParam, value) => {
        onSearchParamsChange([searchParam], value);
    };

    const onAppVersionFilterChange = (value) => {
        onSearchParamsChange('userAppType', value);
        setAppFilter(value);
    };

    const onVerifiedFilterChange = (value) => {
        onSearchParamsChange('verified', value);
        setVerifiedFilter(value);
    };

    const onSearchParamsChange = (paramName, value) => {
        props.onSearchParamsChanged(paramName, value);
    };

    const onUsersSearch = () => props.onUserSearch(props.searchParams);

    const onSearchKeyPress = (e) => {
        if(e.key === 'Enter'){
            onUsersSearch();
        }
    };

    const onUploadLicenseFile = () => uploadInput.click();
    const onFileUploaded = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.readAsText(file);
        reader.onloadend = (a) => {
            let licenseXml = a.target.result.replace(/(\r\n|\t|\n|\r)/gm, "");
            props.onLicenseUpload(licenseXml);
        };
    };

    const createDropDown = (stateProperty, values) => {
        return (<div className="dropdown">
					<button className="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						{props.searchParams[stateProperty]}
					</button>
					<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						{values.map(element => <li key={element} onClick={(e) => selectDropDown(stateProperty, e)}><a className="dropdown-item" href="#">{element}</a></li>)}
					</ul>
				</div>);
    };
    let {searchParams} = props;

    // console.log('SearchFilter', props)
    return(
        <div onKeyPress={onSearchKeyPress} style={{display:"flow-root", paddingBottom: "5px"}}>
            <div className="row search-top-filter-row">
                <div className="col">
                    <input type="text" className="form-control form-control-sm" name="searchString" placeholder="Search ..."
                        onChange={onFormsChange}
                        value={searchParams.searchString}/>
                </div>
                <div className="col-auto">
										<button type="button" className={classNames("btn btn-outline-secondary btn-sm", {"active": collapseFilter})} data-bs-toggle="collapse" data-bs-target="#collapseFilters" aria-controls="collapseFilters" onClick={toggleFilterCollapse}>
											<FilterIcon />
										</button>
                </div>
                <div className="col-sm-auto pb-1">
                    <div className="btn-group w-100" data-toggle="buttons">
                      <input type="radio" className="btn-check" name="options" id="valid" autoComplete="off" onClick={toggleActiveUsers} />
                      <label className={classNames("btn btn-outline-secondary btn-sm", {"active": searchParams.searchActive})} onClick={toggleActiveUsers}>Active</label>
                      <input type="radio" className="btn-check" name="options" id="deleted" autoComplete="off" onClick={toggleActiveUsers} />
                      <label className={classNames("btn btn-outline-secondary btn-sm", {"active": !searchParams.searchActive})} onClick={toggleActiveUsers}>Deleted</label>
                    </div>
                </div>
                <div className="col-sm-auto pb-1">
                    <button type="button" className="btn btn-outline-info btn-sm w-100" onClick={onUsersSearch}>Search</button>
                </div>
                <div className="col-sm-auto pb-1">
                    <button type="button" className="btn btn-outline-danger btn-sm w-100" onClick={props.onResetSearch}>Reset</button>
                </div>
            </div>
            <div className="row">
            <div className="col pb-1">
							<div className="collapse show" id="collapseFilters">
								<div className="card card-body">
									<div className="row">
											<div className="col-md">
													<div className="form-group row">
															<span htmlFor="licenseDays" className="col-sm-4 col-form-label col-form-label-sm">License Days</span>
															<div className="col-sm-8">
																	<input type="number" className="form-control form-control-sm" id="licenseDays" name="licenseDays" placeholder="Days" onChange={onFormsChange}/>
															</div>
													</div>
											</div>
											{/*<div className="col-md">*/}
											{/*		<div className="form-group row">*/}
											{/*				<span htmlFor="ska" className="col-sm-3 col-form-label col-form-label-sm">SKA</span>*/}
											{/*				<div className="col-sm-9">*/}
											{/*						{createDropDown('skaFilter', ['Any', 'Yes', 'No'])}*/}
											{/*				</div>*/}
											{/*		</div>*/}
											{/*</div>*/}
                      <div className="col-md">
                        <div className="form-group row">
                          <span htmlFor="swepFilter" className="col-sm-3 col-form-label col-form-label-sm">SWEP</span>
                          <div className="col-sm-9">
                            {createDropDown('swepFilter', ['Any', 'Yes', 'No'])}
                          </div>
                        </div>
                      </div>
											<div className="col-md">
													<input type="checkbox" className="btn-check" id="btn-check-demo" defaultChecked={searchParams.demoLicences} color="outline-secondary btn-sm" onClick={checkDemoLicenses} active={searchParams.demoLicences.toString()} autoComplete="off"/>
													<label className="btn btn-outline-secondary" htmlFor="btn-check-demo">Demo Licenses</label>
											</div>
									</div>
									<div className="row">
										<div className="col-md">
												<div className="form-group row">
														<span htmlFor="licenseType" className="col-sm-4 col-form-label col-form-label-sm">License Type</span>
														<div className="col-sm-8"><ScaleSpinner loading={!props.packages} height={15} width={2} color="#6c757d" />
																{props.packages &&
																		<MultiSelect isMulti={true}
																				data={props.packages.map(p => {return {value: p.packageId, label: p.name };})}
																				onChange={(value) => onMultiSelChange('licTypeFilter', value)}
																				value={searchParams.licTypeFilter}/>}
														</div>
												</div>
										</div>
                    <div className="col-md">
                      <div className="form-group row">
                        <span htmlFor="userTypeFilter" className="col-sm-3 col-form-label col-form-label-sm">User Type</span>
                        <div className="col-sm-9">
                          {props.userTypes && createDropDown('userTypeFilter', ["Any", ...props.userTypes])}
                        </div>
                      </div>
                    </div>
										<div className="col-md">
												<input type="checkbox" className="btn-check" id="btn-check-expired" defaultChecked={searchParams.expiredLicenses} color="outline-secondary btn-sm" onClick={checkExpiredLicenses} active={searchParams.expiredLicenses.toString()} autoComplete="off"/>
													<label className="btn btn-outline-secondary" htmlFor="btn-check-expired">Expired Licenses</label>
										</div>
									</div>
									<div className="row">
											<div className="col-md">
													<div className="form-group row">
															<span htmlFor="featureNameFilter" className="col-sm-4 col-form-label col-form-label-sm">Feature Name</span>
															<div className="col-sm-8"><ScaleSpinner loading={!props.features} height={15} width={2} color="#6c757d" />
																	{props.features &&
																			<MultiSelect isMulti={true}
																					data={props.features.map(f => {return {value: f.text, label: f.name };})}
																					onChange={(value) => onMultiSelChange('featureNameFilter', value)}
																					value={searchParams.featureNameFilter}/>}
															</div>
													</div>
											</div>
                      <div className="col-md"/>
											<div className="col-md">
													<div className="btn-group" role="group" aria-label="Basic example">
															<button type="button" className={classNames("btn btn-outline-secondary btn-sm", {"active": appFilter === 'BOTH'})} onClick={() => onAppVersionFilterChange('BOTH')}>Any</button>
															<button type="button" className={classNames("btn btn-outline-secondary btn-sm", {"active": appFilter === 'WEB'})} onClick={() => onAppVersionFilterChange('WEB')}>Web</button>
															<button type="button" className={classNames("btn btn-outline-secondary btn-sm", {"active": appFilter === 'DESKTOP'})} onClick={() => onAppVersionFilterChange('DESKTOP')}>Desktop</button>
													</div>
											</div>
									</div>
									<div className="row">
											<div className="col-md">
													<div className="form-group row">
															<span htmlFor="countryFilter" className="col-sm-4 col-form-label col-form-label-sm">Country</span>
															<div className="col-sm-8"><ScaleSpinner loading={!props.countries} height={15} width={2} color="#6c757d" />
																	{props.countries &&
																			<MultiSelect isMulti={true}
																					data={props.countries.map(c => {return {value: c.countryName, label: c.countryName };})}
																					onChange={(value) => onMultiSelChange('countryFilter', value)}
																					value={searchParams.countryFilter}/>}
															</div>
													</div>
											</div>
											{/*<div className="col-md">*/}
											{/*		<div className="form-group row">*/}
											{/*				<span htmlFor="isActiveFilter" className="col-sm-3 col-form-label col-form-label-sm">Is Active</span>*/}
											{/*				<div className="col-sm-9">*/}
											{/*						{createDropDown('isActiveFilter', ['Both', 'Yes', 'No'])}*/}
											{/*				</div>*/}
											{/*		</div>*/}
											{/*</div>*/}
                      <div className="col-md"/>
											<div className="col-md">
													<div className="btn-group" role="group" aria-label="Basic example">
															<button type="button" className={classNames("btn btn-outline-secondary btn-sm", {"active": verifiedFilter === null})} onClick={() => onVerifiedFilterChange(null)}>Any</button>
															<button type="button" className={classNames("btn btn-outline-secondary btn-sm", {"active": verifiedFilter === true})} onClick={() => onVerifiedFilterChange(true)}>Verified</button>
															<button type="button" className={classNames("btn btn-outline-secondary btn-sm", {"active": verifiedFilter === false})} onClick={() => onVerifiedFilterChange(false)}>Not verified</button>
													</div>
											</div>
									</div>
								</div>
							</div>
            </div>
        </div>
            <div className="row float-end search-top-filter-row">
                <div className="col-sm-auto pb-1">
                    <button type="button" className="btn btn-outline-success btn-sm w-100" onClick={onUploadLicenseFile}>Load License File</button>
                    <input type="file" id="file" ref={(input) => {uploadInput = input;}} onChange={onFileUploaded} style={{display: "none"}}/>
                </div>
                <div className="col-sm-auto">
                    <button type="button" className="btn btn-outline-success btn-sm w-100" data-bs-toggle="modal" data-bs-target="#BulkUpdateModal">Bulk Update</button>
                </div>
            </div>
        </div>
    );
};

SearchFilter.propTypes = {
    countries: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    features: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    packages: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    userTypes: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    onUserSearch: PropTypes.func,
    onSearchParamsChanged: PropTypes.func,
    onResetSearch: PropTypes.func,
    searchParams: PropTypes.object,
    onLicenseUpload: PropTypes.func
};

export default SearchFilter;
