import React from "react";
import PropTypes from "prop-types";

export const TblCheckbox = ({indeterminate, className = '', ...rest}) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + ' cursor-pointer'}
      {...rest}
    />
  );
};

TblCheckbox.propTypes = {
  indeterminate: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string
};
