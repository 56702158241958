import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeSnackbar } from './actions';
import {useInjectReducer} from "../../utils/injectReducer";
import reducer from './reducer';
import {makeSelectNotifications} from "./selectors";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

let displayed = [];

const Notifier = () => {
  useInjectReducer({ key: "notifier", reducer: reducer });

  const dispatch = useDispatch();
  const notifications = useSelector(makeSelectNotifications());
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        autoHideDuration: 6000,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(removeSnackbar(myKey));
          removeDisplayed(myKey);
        },
        // eslint-disable-next-line react/display-name
        action: key => (
          <Tooltip title="Dismiss">
            <IconButton edge="start" color="inherit" onClick={() => closeSnackbar(key)} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        ),
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default Notifier;
