import * as consts from './constants';
import { produce } from 'immer';

// The initial state of the App
const initialState = {
  loading: false,
  error: false,
  currentUser: false,
  userData: {
    repositories: false,
  },
  countries: false,
  features: false,
  packages: false,
  warehouses: false,
  usertypes: false
};

const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case consts.LOAD_REPOS:
        draft.loading = true;
        draft.error = false;
        draft.userData.repositories = false;
        break;
      case consts.LOAD_REPOS_SUCCESS:
        draft.userData.repositories = action.repos;
        draft.loading = false;
        draft.currentUser = action.username;
        break;
      case consts.LOAD_REPOS_ERROR:
        draft.error = action.error;
        draft.loading = false;
        break;
      case consts.LOAD_COUNTRIES:
        draft.countries = action.value;
        break;
      case consts.UPDATE_COUNTRY: {
        const {salesContact} = action.value;
        let updatedCountries = state.countries.map(c => {
          if (c.countryName === action.value.country) {
            c = { ...c, salesContact };
          }
          return c;
        });
        draft.countries = updatedCountries;
        break;
      }
      case consts.LOAD_FEATURES:
        draft.features = action.value;
        break;
      case consts.LOAD_PACKAGES:
        draft.packages = action.value;
        break;
      case consts.LOAD_USERTYPES:
        draft.usertypes = action.value;
        break;
      case consts.LOAD_WAREHOUSES:
        draft.warehouses = action.value;
        break;
      case consts.PACKAGE_UPDATED: {
        let packages = state.packages.map(p => {
          if(p.packageId === action.value.packageId){
            p = action.value;
          }
          return p;
        });
        draft.packages = packages;
        break;
      }
      case consts.PACKAGE_CREATED:{
        draft.packages.push(action.value);
        break;
      }
      case consts.PACKAGE_DELETED:
        draft.packages = state.packages.filter(x => x.packageId !== action.value);
        break;
      case consts.FEATURE_CREATED:{
        draft.features.push(action.value);
        break;
      }
      case consts.FEATURE_DELETED:{
        let featsToDel = state.features;
        featsToDel = featsToDel.filter(f => f.featureId !== action.value);
        draft.features = featsToDel;
        break;
      }
      case consts.FEATURE_UPDATED:{
          let features = state.features.map(p => {
            if(p.featureId === action.value.featureId){
              p = action.value;
            }
            return p;
          });
          draft.features = features;
        break;
      }
      case consts.LOGIN_USER:
        draft.loggedInUser = action.value;
        break;
      default:
        return state;
    }
});

export default appReducer;
