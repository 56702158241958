// Needed for redux-saga es6 generator support
import '@babel/polyfill';
// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AppContainer } from 'react-hot-loader';
import { ConnectedRouter } from 'connected-react-router';
import FontFaceObserver from 'fontfaceobserver';
// import {createBrowserHistory} from 'history';
import history from 'utils/history';
import 'sanitize.css/sanitize.css';

// Import root app
import App from './containers/App';

// Import Language Provider
// import {default as LanguageProvider} from 'containers/LanguageProvider';

import configureStore from './configureStore';

// Import i18n messages
import { translationMessages } from './i18n';

// Import CSS reset and Global Styles
import './global-styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/toastr/build/toastr.min.css';
import './style/globalStyle.scss';

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.ejs file and this observer)
const openSansObserver = new FontFaceObserver('Open Sans', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

// Create redux store with history
const store = configureStore();
const MOUNT_NODE = document.getElementById('app');

const render = () => {
  // authentication.run(() => {
    ReactDOM.render(
      <AppContainer>
        <Provider store={store}>
          {/* <LanguageProvider messages={messages}> */}
            <ConnectedRouter history={history}>
              <App />
            </ConnectedRouter>
          {/* </LanguageProvider> */}
        </Provider>
      </AppContainer>,
      MOUNT_NODE,
    );
  // });
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// // Chunked polyfill for browsers without Intl support
// if (!window.Intl) {
//   new Promise(resolve => {
//     resolve(import('intl'));
//   })
//     .then(() =>
//       Promise.all([
//         import('intl/locale-data/jsonp/en.js'),
//         import('intl/locale-data/jsonp/de.js'),
//       ]),
//     )
//     .then(() => render(translationMessages))
//     .catch(err => {
//       throw err;
//     });
// } else {
//   render(translationMessages);
// }

render(translationMessages);


// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}
