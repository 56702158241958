import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import * as consts from 'containers/App/constants';
import { repoLoadingError } from 'containers/App/actions';
import { usersLoaded } from './actions';
import { SEARCH_USERS, USER_DELETE, USER_SEND_VERIFICATION_FOR_FOOTPRINT, USER_SEND_VERIFICATION_FOR_DTHERMX,
  USER_SEND_LICENSE_FILE, USER_DOWNLOAD_LICENSE_FILE, USER_CHECK_NEW_USER, USER_SET_TEMP_PWD, USER_EMAILS_LOGS } from 'utils/links';
import toastr from 'toastr';
import {postRequest, deleteRequest} from 'utils/request';

export function* searchUsers(e){
  try{
    let {searchParams} = e;
    let sp = {
      "keyword": searchParams.searchString,
      page: searchParams.page,
      pageSize: searchParams.pageSize,
      sortParam: searchParams.sortParam,
      sortDesc: searchParams.sortDesc,
    };
    if(searchParams.swepFilter !== "Any") {
        sp.isSwep = searchParams.swepFilter === "Yes";
    }
    if(searchParams.skaFilter !== "Any") {
        sp.isSka = searchParams.skaFilter === "Yes";
    }
    if(searchParams.isActiveFilter !== "Both") {
        sp.isActive = searchParams.isActiveFilter === "Yes";
    }
    if(searchParams.userTypeFilter !== "Any") {
      sp.userType = searchParams.userTypeFilter;
    }
    if(searchParams.userAppType !== "BOTH") {
      sp.userAppType = searchParams.userAppType;
    }
    if(searchParams.verified !== null){
      sp.verified = searchParams.verified;
    }
    if(searchParams.licenseDays) {
        let addDays = function(date, days) {
            date.setDate(date.getDate() + days);
            return date;
        };
        let expiryDate = addDays(new Date(), searchParams.licenseDays * 1);

        sp.licenseExpiry = expiryDate.toISOString();
    }
    if(searchParams.featureNameFilter.length > 0 && searchParams.featureNameFilter !== "Any") {
        sp.features = searchParams.featureNameFilter.map(x => x.value);
    }
    if(searchParams.licTypeFilter.length > 0 && searchParams.licTypeFilter !== "Any") {
        sp.packages = searchParams.licTypeFilter.map(x => x.value);
    }
    if(searchParams.countryFilter !== "Any") {
        sp.countries = searchParams.countryFilter.map(x => x.value);
    }
    if(searchParams.demoLicences) {
        sp.packages = sp.packages || [];
        if(sp.packages && !sp.packages.some(p => p === 1)){
            sp.packages.push(1);
        }
    }
    if(searchParams.expiredLicenses) {
        sp.expiredLicensesOnly = true;
    }
    sp.searchActive = (searchParams.searchActive) ? true : false;

    const users = yield call(postRequest, `${process.env.API_DATAHALL}${SEARCH_USERS}`, sp);
    yield put({type: consts.USER_CLEAR_SELECTED_USERS });
    yield put(usersLoaded(users.data));
  } catch(err){
    yield put(repoLoadingError(err));
  }
}

export function* sendVerificationEmailForFootprint(e){
  try{
    const response = yield call(postRequest, `${process.env.API_DATAHALL}${USER_SEND_VERIFICATION_FOR_FOOTPRINT}`, {email: e.email, footprint: e.footprint});
    if(response){
      toastr.success(`Verification email sent`);
    }
  } catch(err){
    toastr.error(`Error sending verification email (${err})`);
  }
}

export function* onSendVerificationEmailForDThermX(e){
  try{
    const response = yield call(postRequest, `${process.env.API_DATAHALL}${USER_SEND_VERIFICATION_FOR_DTHERMX}`, {email: e.email});
    if(response){
      toastr.success(`Verification email sent`);
    }
  } catch(err){
    toastr.error(`Error sending verification email (${err})`);
  }
}

export function* setTemporaryPassword(e){
  try{
    const response = yield call(postRequest, `${process.env.API_DATAHALL}${USER_SET_TEMP_PWD}`, {email: e.email, tempPassword: e.temporaryPassword});
    if(response){
      toastr.success(`Temporary password set successfully`);
    }
  } catch(err){
    toastr.error(`Error setting temporary password (${err})`);
  }
}

export function* sendLicenseFile(e){
  try{
    const response = yield call(postRequest, `${process.env.API_INTERN}${USER_SEND_LICENSE_FILE}?userId=${e.userId}`);
    if(response){
      toastr.success(`License file sent successfully`);
    }
  } catch(err){
    toastr.error(`Error sending license file (${err})`);
  }
}

export function* downloadLicenseFile(e){
  try{
    const response = yield call(postRequest, `${process.env.API_INTERN}${USER_DOWNLOAD_LICENSE_FILE}?userId=${e.userId}`);
    if(response){
      if(e.callback) {e.callback(response);}
      toastr.success(`License file downloaded successfully`);
    }
  } catch(err){
    toastr.error(`Error downloading license file (${err})`);
  }
}

export function* deleteUser(deleteParam){
  try{
    const response = yield call(deleteRequest, `${process.env.API_DATAHALL}${USER_DELETE}`, { email: deleteParam.value.email, deletedBy: deleteParam.value.deletedBy});
    if(response){
      yield put({type: consts.USER_DELETED, value: deleteParam.value.userId });
      toastr.success('User deleted successfully');
    }
  } catch(err){
    toastr.error(`Error deleting user (${err})`);
  }
}

export function* loadXmlLicense(e){
  try{
    const response = yield call(postRequest, `${process.env.API_INTERN}${USER_CHECK_NEW_USER}`, e.value.licenseXml, { 'content-type': 'application/xml' });
    if(response){
       yield put({type: consts.SELECT_USER_FOR_EDIT, user: response.data});
       yield call(e.value.callback);
      toastr.success(`New license checked successfully`);
    }
  } catch(err){
    toastr.error(`Error checking license file (${err})`);
  }
}

export function* getMe(req){
  try{
    const response = req;

    // const response = yield call(request, `https://graph.microsoft.com/v1.0/me`);
    if(response){
      yield call(req.value.callback, response);
    }
  } catch(err){
      //toastr.error(`Error getting user details from MS Graph`);
  }
}

export function* getEmailsLogs(req){
  try{
    const response = yield call(postRequest, `${process.env.API_DATAHALL}${USER_EMAILS_LOGS}`, { email: req.email});
    if(response){
      yield call(req.callback, response);
    }
  } catch(err){
      toastr.error(`Error getting email logging data`);
  }
}


export default function* homeSaga() {
  yield all([
    takeLatest(consts.LOAD_USERS, searchUsers),
    takeEvery(consts.SAGA_USER_DELETE, deleteUser),
    // takeLatest(consts.SAGA_USER_SEND_VERIFICATION, sendVerificationEmail),
    takeLatest(consts.SAGA_USER_SEND_VERIFICATION_FOR_FOOTPRINT, sendVerificationEmailForFootprint),
    takeLatest(consts.SAGA_USER_SEND_VERIFICATION_FOR_DTHERMX, onSendVerificationEmailForDThermX),
    takeLatest(consts.SAGA_LOAD_EMAILS_LOGS, getEmailsLogs),
    takeLatest(consts.SAGA_SET_TEMP_PASSWORD, setTemporaryPassword),
    takeLatest(consts.SAGA_USER_SEND_LICENSE_FILE, sendLicenseFile),
    takeLatest(consts.SAGA_USER_DOWNLOAD_LICENSE_FILE, downloadLicenseFile),
    takeLatest(consts.SAGA_USER_LOAD_LICENSE_FILE, loadXmlLicense),
    takeLatest(consts.SAGA_LOAD_ME, getMe)
  ]);
}
