import { createSelector } from 'reselect';

const selectXRef = state => state.xref;

const makeSelectConfigurations = () =>
  createSelector(selectXRef, state => state ? state.configs: []);

const makeSelectHXsTypes = () =>
  createSelector(selectXRef, state => state ? state.hxsTypes: []);

const makeSelectHXsSizes = () =>
  createSelector(selectXRef, state => state ? state.hxsSizes: {});

const makeSelectHXsSubtypes = () =>
  createSelector(selectXRef, state => state ? state.hxsSubtypes: {});

const makeSelectManufacturers = () =>
  createSelector(selectXRef, state => state ? state.manufacturers: []);

const makeSelectCompetitorsProducts = () =>
  createSelector(selectXRef, state => state ? state.competitorsProducts: {});

export {
  makeSelectConfigurations,
  makeSelectHXsTypes,
  makeSelectHXsSizes,
  makeSelectHXsSubtypes,
  makeSelectManufacturers,
  makeSelectCompetitorsProducts
};
