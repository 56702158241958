// /**
//  * Create the store with dynamic reducers
//  */

// import { createStore, applyMiddleware, compose } from 'redux';
// import { fromJS } from 'immutable';
// import { routerMiddleware, connectRouter } from 'connected-react-router';
// import createSagaMiddleware from 'redux-saga';
// import createReducer from './reducers';

// const connectRouterHistory = connectRouter(history);
// const sagaMiddleware = createSagaMiddleware();

// export default function configureStore(initialState = {}, history) {
//   // Create the store with two middlewares
//   // 1. sagaMiddleware: Makes redux-sagas work
//   // 2. routerMiddleware: Syncs the location/URL path to the state
//   const middlewares = [sagaMiddleware, routerMiddleware(history)];

//   const enhancers = [applyMiddleware(...middlewares)];

//   // If Redux DevTools Extension is installed use it, otherwise use Redux compose
//   /* eslint-disable no-underscore-dangle, indent */
//   const composeEnhancers =
//     process.env.NODE_ENV !== 'production' &&
//     typeof window === 'object' &&
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//       ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//           shouldHotReload: false,
//         })
//       : compose;
//   /* eslint-enable */

//   const store = createStore(
//     createReducer(),
//     initialState,
//     composeEnhancers(...enhancers),
//   );

//   // Extensions
//   store.runSaga = sagaMiddleware.run;
//   store.injectedReducers = {}; // Reducer registry
//   store.injectedSagas = {}; // Saga registry

//   // Make reducers hot reloadable, see http://mxs.is/googmo
//   /* istanbul ignore next */
//   if (module.hot) {
//     module.hot.accept('./reducers', () => {
//       store.replaceReducer(createReducer(store.injectedReducers));
//     });
//   }

//     // Make reducers hot reloadable, see http://mxs.is/googmo
//   /* istanbul ignore next */
//   if (module.hot) {
//     module.hot.accept('./reducers', () => {
//     const nextRootReducer = require('./reducers').default; // eslint-disable-line global-require
//     store.replaceReducer(connectRouterHistory(nextRootReducer));
//     // store.replaceReducer(createReducer(store.injectedReducers));
//    });
//   }

//   return store;
// }

import {createStore, compose, applyMiddleware} from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
// import thunk from 'redux-thunk';
import createReducer from './reducers';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import 'regenerator-runtime/runtime';
import createSagaMiddleware from 'redux-saga';

const connectRouterHistory = connectRouter(history);
let reduxSagaMonitorOptions = {};

function configureStore(initialState, history) {
  let composeEnhancers = compose;

  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});

      ////Bellow is code for REDUX-SAGA extension
      // reduxSagaMonitorOptions.sagaMonitor = window["__SAGA_MONITOR_EXTENSION__"];
  }

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const middlewares = [
    reduxImmutableStateInvariant(),
    // thunk,
    routerMiddleware(history),
    sagaMiddleware
  ];

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(
    createReducer(), // root reducer with router state
    initialState,
    composeEnhancers(...enhancers),
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
      module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers').default; // eslint-disable-line global-require
      store.replaceReducer(connectRouterHistory(nextRootReducer));
      // store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}
export default configureStore;

