import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UserInfoDetails from './UserInfoDetails';
import qs from 'query-string';
import injectSaga from 'utils/injectSaga';
import * as consts from '../App/constants';
import saga from './saga';
import '../../style/user-info-page.scss';

// require('../style/user-info-page.scss');

class UserInfoPage extends React.PureComponent {
  constructor(props) {
    super(props);

    let queryStringParams = qs.parse(this.props.location.search);

    this.state = {
      licenseId: queryStringParams.id,
      loading: false,
      userInfo: {
        Username: ' --- ',
        Email: ' --- ',
        UserType: ' --- ',
        CreatedOn: ' --- ',
        LicenseExpires: ' --- ',
        LastChanged: ' --- ',
      },
    };

    this.getUserInfo = this.getUserInfo.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setUser = this.setUser.bind(this);
    // this.typeChanged = this.typeChanged.bind(this);
  }

  handleChange(e) {
    this.setState({ licenseId: e.target.value });
  }

  getUserInfo() {
    const { licenseId } = this.state;
    this.props.getUserInfo(licenseId, this.setUser);
  }
  setUser(userData) {
    this.setState({
      userInfo: {
        Username: `${userData.data.FirstName} ${userData.data.SecondName} `,
        Email: `${userData.data.Email}`,
        UserType: userData.data.UserType,
        CreatedOn: userData.data.CreatedOn,
        LicenseExpires: userData.data.License.LicenseExpires,
        LastChanged: userData.data.License.EditedOn,
        IfsId: userData.data.CrmUserName,
      },
    });
  }

  render() {
    return (
      <div className="container">
        <fieldset className="for-panel">
          <legend>Contact Info</legend>
          <div className="row">
            <div className="col-sm-4">
              <div className="form-horizontal">
                <label className="col-xs-5 control-label">ID:</label>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-horizontal">
                <input
                  className="form-control"
                  type="text"
                  placeholder="..."
                  onChange={this.handleChange}
                  value={this.state.licenseId}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="form-horizontal" />
            </div>
            <div className="col-sm-6">
              <div className="form-horizontal text-end">
                <button type="button" className="btn btn-outline-danger"
                  onClick={this.getUserInfo}
                  disabled={this.state.loading === true}
                >
                  Get user info
                </button>
              </div>
            </div>
          </div>
          <br />
          <UserInfoDetails userInfo={this.state.userInfo} />
        </fieldset>
      </div>
    );
  }
}

UserInfoPage.propTypes = {
  location: PropTypes.object,
  getUserInfo: PropTypes.func
};

export function mapStateToProps(state) {
  return {
    user: state,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    getUserInfo: (licenseId, callback) => {
      dispatch({
        type: consts.SAGA_USER_INFO,
        value: {
          licenseId,
          callback,
        },
      });
    },
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withSaga = injectSaga({ key: 'userInfo', saga });

export default compose(
  withSaga,
  withConnect,
)(UserInfoPage);
