import {
  all,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import * as consts from 'containers/App/constants';
import toastr from 'toastr';
import {UPDATE_USER} from 'utils/links';
import { postRequest } from 'utils/request';

export function* updateUser(request) {
  try {
    const response = yield call(
      postRequest,
      `${process.env.API_DATAHALL}${UPDATE_USER}`,
      request.user,
    );
    if (response.data) {
      toastr.success(`User ${response.data.email} has been updated`);
      yield put({ type: consts.USER_UPDATED, value: response.data });
      if(request.callback){
        request.callback();
      }
    } else {
      toastr.warning(`User ${request.user.email} has not been updated`);
    }
  } catch (err) {
    toastr.error(`Error updating user ${request.user.email} (${err}).`);
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* userSaga() {
  yield all([takeEvery(consts.SAGA_USER_UPDATE, updateUser)]);
}
