import { all, call, put, takeLatest } from 'redux-saga/effects';
import {SAGA_LOAD_CONFIGURATIONS, REDUX_XREF_CONFIGS_LOAD, SAGA_XREF_HXS_TYPES, REDUX_XREF_HXS_TYPES_LOAD,
  SAGA_XREF_HXS_SIZES, REDUX_XREF_HXS_SIZES_LOAD,
  SAGA_UPDATE_ALTERNATIVES, REDUX_UPDATE_ALTERNATIVES,
  SAGA_UPDATE_CONFIG, REDUX_UPDATE_CONFIG,
  SAGA_GET_MANUFACTURERS, REDUX_LOAD_MANUFACTURERS,
  SAGA_GET_PRODUCTS_BY_MANUFACTURERS, REDUX_LOAD_MANUF_PRODUCTS,
  SAGA_ADD_CONFIG, REDUX_ADD_CONFIG, SAGA_DELETE_CONFIG, REDUX_DELETE_CONFIG,
  SAGA_ADD_XPRODUCT, SAGA_UPDATE_XPRODUCT, SAGA_DELETE_XPRODUCT,
  REDUX_ADD_XPRODUCT, REDUX_UPDATE_XPRODUCT, REDUX_DELETE_XPRODUCT, SAGA_XREF_STATS_REQUESTSBYDATE, SAGA_XREF_HXS_SUBTYPES, REDUX_XREF_HXS_SUBTYPES_LOAD} from 'containers/App/constants';
import toastr from 'toastr';
import {XREF_CONFIGS_LOAD, XREF_HXS_TYPES, XREF_HXS_SIZES, XREF_UPDATE_ALTERNATIVES, XREF_CONFIG,
  XREF_GET_MANUFACTURERS, XREF_PRODUCTS_BY_MANUFACTURER, XREF_XPRODUCTS, XREF_STATS_REQUESTS} from 'utils/links';

import request, { putRequest, postRequest, deleteRequest } from 'utils/request';
import { XREF_HXS_SUBTYPES } from '../../utils/links';

export function* loadManufacturers() {
  try {
    const response = yield call(request,`${process.env.API_DATAHALL}${XREF_GET_MANUFACTURERS}`);
    if (response) {
      yield put({ type: REDUX_LOAD_MANUFACTURERS, value: response });
    }
  } catch (err) {
    toastr.error(`Error loading cross reference mapping`);
  }
}

export function* loadProductsByManufacturers(action) {
  try {
    const response = yield call(request,`${process.env.API_DATAHALL}${XREF_PRODUCTS_BY_MANUFACTURER}/${action.manufacturer}`);
    if (response) {
      yield put({ type: REDUX_LOAD_MANUF_PRODUCTS, manufacturer: action.manufacturer, value: response, action });
    }
  } catch (err) {
    toastr.error(`Error loading cross reference mapping`);
  }
}


export function* loadConfigurations() {
  try {
    const response = yield call(request,`${process.env.API_DATAHALL}${XREF_CONFIGS_LOAD}`);
    if (response) {
      yield put({ type: REDUX_XREF_CONFIGS_LOAD, value: response });
    }
  } catch (err) {
    toastr.error(`Error loading cross reference mapping`);
  }
}

export function* getExchangerTypes() {
  try {
    const response = yield call(request,`${process.env.API_DATAHALL}${XREF_HXS_TYPES}`);
    if (response) {
      yield put({ type: REDUX_XREF_HXS_TYPES_LOAD, value: response });
    }
  } catch (err) {
    toastr.error(`Error loading exchanger types`);
  }
}

export function* getExchangersSizes(action) {
  try {
    const response = yield call(request,`${process.env.API_DATAHALL}${XREF_HXS_SIZES}/${action.xpcType}`);
    if (response) {
      yield put({ type: REDUX_XREF_HXS_SIZES_LOAD, value: response, xpcType: action.xpcType });
    }
  } catch (err) {
    toastr.error(`Error loading exchanger types`);
  }
}

export function* getExchangersSubtypes(action) {
  try {
    const response = yield call(request,`${process.env.API_DATAHALL}${XREF_HXS_SUBTYPES}/${action.xpcType}/${action.xpcSize}`);
    if (response) {
      yield put({ type: REDUX_XREF_HXS_SUBTYPES_LOAD, value: response, xpcType: action.xpcType, xpcSize: action.xpcSize });
    }
  } catch (err) {
    toastr.error(`Error loading exchanger subtypes`);
  }
}


export function* addConfig(action) {
  try {
    const request = {
      region: action.region,
      mappings: action.mappings || []
    };
    const response = yield call(postRequest,`${process.env.API_DATAHALL}${XREF_CONFIG}`, request);
    if (response && response.data) {
      yield put({ type: REDUX_ADD_CONFIG, config: response.data});
    }
  } catch (err) {
    toastr.error(`Error adding configuration`);
  }
}

export function* updateConfig(action) {
  try {
    const response = yield call(putRequest,`${process.env.API_DATAHALL}${XREF_CONFIG}`, action.config);
    if (response && response.data === true) {
      yield put({ type: REDUX_UPDATE_CONFIG, config: action.config});
    }
  } catch (err) {
    toastr.error(`Error updating configuration`);
  }
}

export function* deleteConfig(action) {
  try {
    const response = yield call(deleteRequest,`${process.env.API_DATAHALL}${XREF_CONFIG}/${action.region}`);
    if (response && response.data === true) {
      yield put({ type: REDUX_DELETE_CONFIG, region: action.region});
    }
  } catch (err) {
    toastr.error(`Error updating configuration`);
  }
}

export function* updateCompetitorAlternatives(action) {
  try {
    const request = {
      mappingId: action.mappingId,
      model: action.model,
      alternatives: action.alternatives
    };
    const response = yield call(putRequest,`${process.env.API_DATAHALL}${XREF_UPDATE_ALTERNATIVES}`, request);
    if (response && response.data === true) {
      yield put({ type: REDUX_UPDATE_ALTERNATIVES, ...request});
    }
  } catch (err) {
    toastr.error(`Error updating competitor's alternative products`);
  }
}

export function* addXProduct(action) {
  try {
    const response = yield call(postRequest,`${process.env.API_DATAHALL}${XREF_XPRODUCTS}`, action.product);
    if (response && response.data) {
      yield put({ type: REDUX_ADD_XPRODUCT, product: response.data});
    }
  } catch (err) {
    toastr.error(`Error adding XRef product`);
  }
}

export function* updateXProduct(action) {
  try {
    const response = yield call(putRequest,`${process.env.API_DATAHALL}${XREF_XPRODUCTS}`, action.product);
    if (response && response.data === true) {
      yield put({ type: REDUX_UPDATE_XPRODUCT, product: action.product});
    }
  } catch (err) {
    toastr.error(`Error updating XRef product`);
  }
}

export function* deleteXProduct(action) {
  try {
    const response = yield call(deleteRequest,`${process.env.API_DATAHALL}${XREF_XPRODUCTS}/${action.product._id}`);
    if (response && response.data === true) {
      yield put({ type: REDUX_DELETE_XPRODUCT, manufacturer: action.product.manufacturer, pid: action.product._id});
    }
  } catch (err) {
    toastr.error(`Error deleting XRef product`);
  }
}

export function* getRequestsStatistics(action) {
  try {
    const response = yield call(request,`${process.env.API_DATAHALL}${XREF_STATS_REQUESTS}`);
    if (response) {
      action.cb(response);
    }
  } catch (err) {
    toastr.error(`Error loading exchanger types`);
  }
}

export default function* xrefSaga() {
  yield all([
    takeLatest(SAGA_GET_MANUFACTURERS, loadManufacturers),
    takeLatest(SAGA_GET_PRODUCTS_BY_MANUFACTURERS, loadProductsByManufacturers),
    takeLatest(SAGA_LOAD_CONFIGURATIONS, loadConfigurations),
    takeLatest(SAGA_XREF_HXS_TYPES, getExchangerTypes),
    takeLatest(SAGA_XREF_HXS_SIZES, getExchangersSizes),
    takeLatest(SAGA_XREF_HXS_SUBTYPES, getExchangersSubtypes),
    takeLatest(SAGA_ADD_CONFIG, addConfig),
    takeLatest(SAGA_UPDATE_CONFIG, updateConfig),
    takeLatest(SAGA_DELETE_CONFIG, deleteConfig),
    takeLatest(SAGA_UPDATE_ALTERNATIVES, updateCompetitorAlternatives),
    takeLatest(SAGA_ADD_XPRODUCT, addXProduct),
    takeLatest(SAGA_UPDATE_XPRODUCT, updateXProduct),
    takeLatest(SAGA_DELETE_XPRODUCT, deleteXProduct),
    takeLatest(SAGA_XREF_STATS_REQUESTSBYDATE, getRequestsStatistics),
  ]);
}
