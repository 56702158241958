import { hot } from "react-hot-loader/root";
import React, { useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './web.config';
import styled from 'styled-components';
import HomePage from '../HomePage/index';
import PowerUsersPage from '../PowerUsersPage/Loadable';
import CustomizedAdminPage from '../CustomizedAdminPage/Loadable';
import DThermXAdminPage from '../DThermXAdminPage/Loadable';
import ApiAdminPage from '../ApiAdminPage/Loadable';
import NotFoundPage from '../NotFoundPage/Loadable';
import NotAuthorizedPage from '../NotAuthorizedPage/Loadable';
import LoadingPage from '../LoadingPage/Loadable';
import UserEditPage from '../UserEditPage/Loadable';
import PackagesAndFeaturesPage from '../PackagesAndFeaturesPage/Loadable';
import UserInfoPage from '../UserInfoPage/UserInfoPage';
import CrossReferenceAdminPage from '../CrossReferenceAdminPage/Loadable';
import CountriesConfig from '../CrossReferenceAdminPage/CountriesConfig/CountriesConfig';
import XRefStatistics from '../CrossReferenceAdminPage/XRefStatistics/Loadable';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import 'react-virtualized/styles.css'; // only needs to be imported once
import saga from './saga';
import reducer from './reducer';
import * as consts from './constants';
import * as selectors from './selectors';
import { createStructuredSelector } from 'reselect';
import 'bootstrap';
import Notifier from '../../components/Notifier/Notifier';
import { SnackbarProvider } from 'notistack';
import Zoom from '@material-ui/core/Zoom';
import {useInjectReducer} from "../../utils/injectReducer";
import {useInjectSaga} from "../../utils/injectSaga";

const notificationAnchor = { vertical: 'top', horizontal: 'right' };

const AppWrapper = styled.div`
  // max-width: calc(768px + 16px * 2);
  margin: 0 auto;
  display: flex;
  min-height: 100%;
  padding: 0 16px;
  flex-direction: column;
`;

const HeaderDivider = styled.hr`
  background-color: #046d80;
  height: 2px;
`;

const App = (props) => {
  useInjectReducer({ key: 'appreducer', reducer});
  useInjectSaga({ key: 'appsaga', saga});

  const {getUser, loadCountries, loadPackages, loadFeatures, loadWarehouses, loadUsertypes} = props;

  useEffect(() => {

    /*  getUser(jwtUser.unique_name);*/
    // todo: delete getUser parmeterr
    getUser("swep@swep.net");
    loadCountries();
    loadPackages();
    loadFeatures();
    loadWarehouses();
    loadUsertypes();

    //const initLoading = () => {
    //  let parseJwt = token => {
    //    if (token) {
    //      //console.log('token', token);
    //      let base64Url = token.split('.')[1];
    //      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    //      return JSON.parse(window.atob(base64));
    //    }
    //  };
    //  let helloItem = localStorage.getItem('hello');
    //  if (helloItem) {
    //    let jwtUser = parseJwt(helloItem);
    //    getUser(jwtUser.unique_name);
    //    loadCountries();
    //    loadPackages();
    //    loadFeatures();
    //    loadWarehouses();
    //    loadUsertypes();
    //  }
    //};

    //authService.login(initLoading);
    //authService.getAuthResponse();
  }, [getUser, loadCountries, loadPackages, loadFeatures, loadWarehouses, loadUsertypes]);

  if(!props.loggedUser){
    return null;
  }

  const checkAndAddRoute = (path, component) => {
    if (!props.loggedUser) {
      return <Route path={path} component={LoadingPage} />;
    } else if (!props.loggedUser.isAdmin) {
      return <Route path={path} component={NotFoundPage} />;
    } else {
      return <Route path={path} component={component} />;
    }
  };

  return (
    <SnackbarProvider anchorOrigin={notificationAnchor} TransitionComponent={Zoom}>
      <div>
        <Helmet
          titleTemplate="%s - SSP LicenseMan"
          defaultTitle="SSP LicenseMan"
        >
          <meta
            name="description"
            content="License management system for SSP users"
          />
        </Helmet>
        <Header loggedUser={props.loggedUser} />
        <HeaderDivider />
        <Notifier/>
        <AppWrapper>
          <Switch>
            <Route exact path="/" component={HomePage} />
            {checkAndAddRoute('/packagesandfeatures', PackagesAndFeaturesPage)}
            {checkAndAddRoute('/powerusers', PowerUsersPage)}
            {checkAndAddRoute('/customizedAdmin', CustomizedAdminPage)}
            {checkAndAddRoute('/dthermXAdmin', DThermXAdminPage)}
            {checkAndAddRoute('/apiAdmin', ApiAdminPage)}
            <Route path="/useredit" component={UserEditPage} />
            <Route path="/userinfo" component={UserInfoPage} />
            {(props.loggedUser.isAdmin || props.loggedUser.xRefAdmin) && <Route path="/xrefadmin" component={CrossReferenceAdminPage} />}
            {(props.loggedUser.isAdmin || props.loggedUser.xRefAdmin) && <Route path="/countriesconfig" component={CountriesConfig} />}
            {(props.loggedUser.isAdmin || props.loggedUser.xRefAdmin) && <Route path="/xrefstatistics" component={XRefStatistics} />}
            <Route path="/notauthorized" component={NotAuthorizedPage} />
            <Route path="" component={NotFoundPage} />
          </Switch>
          <Footer />
        </AppWrapper>
      </div>
    </SnackbarProvider>
  );
};

App.propTypes = {
  getUser: PropTypes.func,
  loadCountries: PropTypes.func,
  loadPackages: PropTypes.func,
  loadFeatures: PropTypes.func,
  loadWarehouses: PropTypes.func,
  loadUsertypes: PropTypes.func,
  loggedUser: PropTypes.object
};

const withConnect = connect(
  createStructuredSelector({
    loggedUser: selectors.selectLoggedInUser(),
  }),
  dispatch => {
    return {
      getUser: email =>
        dispatch({ type: consts.SAGA_POWERUSERS_GETBYEMAIL, value: email }),
      loadCountries: () => dispatch({ type: consts.SAGA_LOAD_COUNTRIES }),
      loadPackages: () => dispatch({ type: consts.SAGA_LOAD_PACKAGES }),
      loadFeatures: () => dispatch({ type: consts.SAGA_LOAD_FEATURES }),
      loadWarehouses: () => dispatch({ type: consts.SAGA_LOAD_WAREHOUSES }),
      loadUsertypes: () => dispatch({ type: consts.SAGA_LOAD_USERTYPES }),
    };
  },
);

export default compose(
  withRouter,
  withConnect,
)(hot(App));
