export const LOAD_REPOS = 'boilerplate/App/LOAD_REPOS';
export const LOAD_REPOS_SUCCESS = 'boilerplate/App/LOAD_REPOS_SUCCESS';
export const LOAD_REPOS_ERROR = 'boilerplate/App/LOAD_REPOS_ERROR';

export const CHANGE_USERNAME = 'boilerplate/Home/CHANGE_USERNAME';
export const LOGIN_USER = 'LOGIN_USER';
export const SAGA_LOAD_COUNTRIES = 'SAGA_LOAD_COUNTRIES';
export const LOAD_COUNTRIES = 'LOAD_COUNTRIES';
export const SAGA_UPDATE_COUNTRY = 'SAGA_UPDATE_COUNTRY';
export const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
export const LOAD_PACKAGES = 'LOAD_PACKAGES';
export const SAGA_LOAD_PACKAGES = 'SAGA_LOAD_PACKAGES';
export const LOAD_FEATURES = 'LOAD_FEATURES';
export const SAGA_LOAD_FEATURES = 'SAGA_LOAD_FEATURES';
export const LOAD_WAREHOUSES = 'LOAD_WAREHOUSES';
export const SAGA_LOAD_WAREHOUSES = 'SAGA_LOAD_WAREHOUSES';
export const LOAD_USERTYPES = 'LOAD_USERTYPES';
export const SAGA_LOAD_USERTYPES = 'SAGA_LOAD_USERTYPES';

export const LOAD_USERS = 'LOAD_USERS';
export const USERS_LOADED = 'USERS_LOADED';
export const SAGA_USER_UPDATE = 'SAGA_USER_UPDATE';
export const USER_UPDATED = 'USER_UPDATED';
export const USER_SELECT = 'USER_SELECT';
export const USER_SELECT_ALL = 'USER_SELECT_ALL';
export const USER_CLEAR_SELECTED_USERS = 'USER_CLEAR_SELECTED_USERS';
export const SAGA_USER_DELETE = 'SAGA_USER_DELETE';
export const SAGA_USER_SEND_VERIFICATION = 'SAGA_USER_SEND_VERIFICATION';
export const SAGA_USER_SEND_VERIFICATION_FOR_FOOTPRINT = 'SAGA_USER_SEND_VERIFICATION_FOR_FOOTPRINT';
export const SAGA_USER_SEND_VERIFICATION_FOR_DTHERMX = 'SAGA_USER_SEND_VERIFICATION_FOR_DTHERMX';
export const SAGA_SET_TEMP_PASSWORD = 'SAGA_SET_TEMP_PASSWORD';
export const SAGA_USER_SEND_LICENSE_FILE = 'SAGA_USER_SEND_LICENSE_FILE';
export const SAGA_USER_DOWNLOAD_LICENSE_FILE = "SAGA_USER_DOWNLOAD_LICENSE_FILE";
export const SAGA_USER_LOAD_LICENSE_FILE = 'SAGA_USER_LOAD_LICENSE_FILE';
export const USER_DELETED = 'USER_DELETED';
export const SAGA_LOAD_ME = 'SAGA_LOAD_ME';
export const SAGA_LOAD_EMAILS_LOGS = 'SAGA_LOAD_EMAILS_LOGS';

export const PACKAGES_SELECT = 'PACKAGES_SELECT';
export const SAGA_PACKAGES_GETFEATURES = 'SAGA_PACKAGES_GETFEATURES';
export const PACKAGES_FEATURES_LOADED = 'PACKAGES_FEATURES_LOADED';
export const SAGA_PACKAGES_UPDATE = 'SAGA_PACKAGES_UPDATE';
export const SAGA_PACKAGES_CREATE = 'SAGA_PACKAGES_CREATE';
export const SAGA_PACKAGES_DELETE = 'SAGA_PACKAGES_DELETE';
export const PACKAGE_UPDATED = 'PACKAGE_UPDATED';
export const PACKAGE_CREATED = 'PACKAGE_CREATED';
export const PACKAGE_DELETED = 'PACKAGE_DELETED';
export const SAGA_COUNT_USERS_BY_PACKAGE = 'SAGA_COUNT_USERS_BY_PACKAGE';

export const FEATURES_SELECT = 'FEATURES_SELECT';
export const FEATURE_CREATED = 'FEATURE_CREATED';
export const FEATURE_UPDATED = 'FEATURE_UPDATED';
export const FEATURE_DELETED = 'FEATURE_DELETED';
export const FEATURE_PACKAGES_LOADED = 'FEATURE_PACKAGES_LOADED';
export const SAGA_FEATURE_GETPACKAGES = 'SAGA_FEATURE_GETPACKAGES';
export const SAGA_FEATURES_UPDATE = 'SAGA_FEATURES_UPDATE';
export const SAGA_FEATURES_CREATE = 'SAGA_FEATURES_CREATE';
export const SAGA_FEATURES_DELETE = 'SAGA_FEATURES_DELETE';
export const SAGA_COUNT_USERS_BY_FEATURE = 'SAGA_COUNT_USERS_BY_FEATURE';

/* power users */

export const SAGA_POWERUSERS_GETALL = 'SAGA_POWERUSERS_GETALL';
export const SAGA_POWERUSERS_GETONE = 'SAGA_POWERUSERS_GETONE';
export const SAGA_POWERUSERS_GETBYEMAIL = 'SAGA_POWERUSERS_GETBYEMAIL';
export const SAGA_POWERUSERS_UPDATE = 'SAGA_POWERUSERS_UPDATE';
export const SAGA_POWERUSERS_CREATE = 'SAGA_POWERUSERS_CREATE';
export const SAGA_POWERUSERS_DELETE = 'SAGA_POWERUSERS_DELETE';
export const SAGA_POWERUSERS_SEARCH_GRAPHAPI = 'SAGA_POWERUSERS_SEARCH_GRAPHAPI';
export const POWERUSERS_GETALL = 'POWERUSERS_GETALL';
export const POWERUSERS_SELECT = 'POWERUSERS_SELECT';
export const POWERUSER_UPDATED = 'POWERUSER_UPDATED';
export const POWERUSER_CREATED = 'POWERUSER_CREATED';
export const POWERUSER_DELETED = 'POWERUSER_DELETED';
export const SAGA_USER_INFO = 'SAGA_USER_INFO';
export const SAGA_USER_ACTIVATE_FOOTPRINT = 'SAGA_USER_ACTIVATE_FOOTPRINT';

export const SEARCH_PARAMS_CHANGED = 'SEARCH_PARAMS_CHANGED';
export const RESET_SEARCH = 'RESET_SEARCH';

export const SELECT_USER_FOR_EDIT = 'SELECT_USER_FOR_EDIT';

// Cross reference tool
export const SAGA_LOAD_CONFIGURATIONS = 'SAGA.LOAD_CONFIGURATIONS';
export const REDUX_XREF_CONFIGS_LOAD = 'REDUX.LoadXREFConfigs';
export const SAGA_XREF_HXS_TYPES = 'SAGA.GetExchagersTypes';
export const REDUX_XREF_HXS_TYPES_LOAD = 'REDUX.LoadExchangersTypes';
export const SAGA_XREF_HXS_SIZES = 'SAGA.GetExchagersSizes';
export const SAGA_XREF_HXS_SUBTYPES = 'SAGA.GetExchagersSubtypes';
export const REDUX_XREF_HXS_SIZES_LOAD = 'REDUX.LoadExchangersSizes';
export const REDUX_XREF_HXS_SUBTYPES_LOAD = 'REDUX.LoadExchangersSubtypes';
export const SAGA_UPDATE_ALTERNATIVES = 'SAGA_UPDATE_ALTERNATIVES';
export const REDUX_UPDATE_ALTERNATIVES = 'REDUX_UPDATE_ALTERNATIVES';
export const SAGA_UPDATE_CONFIG = 'SAGA_UPDATE_CONFIG';
export const REDUX_UPDATE_CONFIG = 'REDUX_UPDATE_CONFIG';
export const SAGA_GET_MANUFACTURERS = 'SAGA_GET_MANUFACTURERS';
export const REDUX_LOAD_MANUFACTURERS = 'REDUX_LOAD_MANUFACTURERS';
export const SAGA_GET_PRODUCTS_BY_MANUFACTURERS = 'SAGA_GET_PRODUCTS_BY_MANUFACTURERS';
export const REDUX_LOAD_MANUF_PRODUCTS = 'REDUX_LOAD_MANUF_PRODUCTS';
export const SAGA_ADD_CONFIG = 'SAGA_ADD_CONFIG';
export const REDUX_ADD_CONFIG = 'REDUX_ADD_CONFIG';
export const SAGA_DELETE_CONFIG = 'SAGA_DELETE_CONFIG';
export const REDUX_DELETE_CONFIG = 'REDUX_DELETE_CONFIG';
export const SAGA_ADD_XPRODUCT = 'SAGA_ADD_XPRODUCT';
export const SAGA_UPDATE_XPRODUCT = 'SAGA_UPDATE_XPRODUCT';
export const SAGA_DELETE_XPRODUCT = 'SAGA_DELETE_XPRODUCT';
export const REDUX_ADD_XPRODUCT = 'REDUX_ADD_XPRODUCT';
export const REDUX_UPDATE_XPRODUCT = 'REDUX_UPDATE_XPRODUCT';
export const REDUX_DELETE_XPRODUCT = 'REDUX_DELETE_XPRODUCT';
export const SAGA_XREF_STATS_REQUESTSBYDATE = 'SAGA_XREF_STATS_REQUESTSBYDATE';
