import {
  REDUX_XREF_CONFIGS_LOAD, REDUX_XREF_HXS_TYPES_LOAD, REDUX_XREF_HXS_SIZES_LOAD,
  REDUX_UPDATE_ALTERNATIVES, REDUX_UPDATE_CONFIG, REDUX_LOAD_MANUFACTURERS,
  REDUX_LOAD_MANUF_PRODUCTS, REDUX_ADD_CONFIG, REDUX_DELETE_CONFIG,
  REDUX_ADD_XPRODUCT, REDUX_UPDATE_XPRODUCT, REDUX_DELETE_XPRODUCT, REDUX_XREF_HXS_SUBTYPES_LOAD,
} from '../App/constants';
import { produce } from 'immer';

// The initial state of the App
const initialState = {
  configs: [],
  hxsTypes: [],
  hxsSizes: {},
  hxsSubtypes: {},
  manufacturers: [],
  competitorsProducts: {}
};

const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case REDUX_XREF_CONFIGS_LOAD:
        draft.configs = action.value;
        break;
      case REDUX_XREF_HXS_TYPES_LOAD:
        draft.hxsTypes = action.value;
        break;
      case REDUX_XREF_HXS_SIZES_LOAD:
        draft.hxsSizes[action.xpcType] = action.value;
        break;
      case REDUX_XREF_HXS_SUBTYPES_LOAD:
        draft.hxsSubtypes[action.xpcType] = {
          ...draft.hxsSubtypes[action.xpcType],
          [action.xpcSize]: action.value
        };
        break;
      case REDUX_UPDATE_ALTERNATIVES:
        draft.configs.forEach((x) => {
          if(x._id === action.mappingId){
            x.mappings.forEach((m, j) => {
              if(m.model === action.model){
                x.mappings[j].alternatives = action.alternatives;
              }
            });
          }
        });
        break;
      case REDUX_UPDATE_CONFIG:
        draft.configs[draft.configs.findIndex(x => x._id === action.config._id)] = action.config;
        break;
      case REDUX_ADD_CONFIG:
        draft.configs.push(action.config);
        break;
      case REDUX_DELETE_CONFIG:
        draft.configs = state.configs.filter(x => x.region !== action.region);
        break;
      case REDUX_LOAD_MANUFACTURERS:
        draft.manufacturers = action.value;
        break;
      case REDUX_LOAD_MANUF_PRODUCTS:
        draft.competitorsProducts[action.manufacturer] = action.value;
        break;
      case REDUX_ADD_XPRODUCT:
        draft.competitorsProducts[action.product.manufacturer].push(action.product);
        break;
      case REDUX_UPDATE_XPRODUCT: {
        const index = state.competitorsProducts[action.product.manufacturer].findIndex(x => x._id === action.product._id);
        draft.competitorsProducts[action.product.manufacturer][index] = action.product;
        draft.configs = null;
        break;
      }
      case REDUX_DELETE_XPRODUCT:
        draft.competitorsProducts[action.manufacturer] = state.competitorsProducts[action.manufacturer].filter(x => x._id !== action.pid);
        break;
      default:
        return state;
    }
});

export default appReducer;
