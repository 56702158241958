import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as consts from 'containers/App/constants';
import toastr from 'toastr';
import * as links from 'utils/links';

import request, {putRequest} from 'utils/request';

export function* getByEmail(e) {
  try {
    const response = yield call(
      request,
      `${process.env.API_DATAHALL}${links.POWERUSER_GETBY_EMAIL}/${
        e.value
      }`,
    );

    if (response) {
      yield put({ type: consts.LOGIN_USER, value: response });
    }
  } catch (err) {
    toastr.error(`Error loading users details`);
  }
}

export function* loadCountries() {
  try {
    const response = yield call(request, `${process.env.API_DATAHALL}${links.GET_COUNTRIES}/full`);
    if (response) {
      yield put({ type: consts.LOAD_COUNTRIES, value: response });
    }
  } catch (err) {
    toastr.error(`Error loading countries`);
  }
}



export function* updateCountry(action) {
  try {
    const {country, salesContact} = action;

    let countryObject = {
      countryName: country,
      salesContact
    };

    const response = yield call(
      putRequest,
      `${process.env.API_DATAHALL}${links.GET_COUNTRIES}`,
      countryObject
    );
    if (response) {
      yield put({ type: consts.UPDATE_COUNTRY, value: {country, salesContact} });
    }
  } catch (err) {
    toastr.error(`Error updating country`);
  }
}

export function* loadPackages() {
  try {
    const response = yield call(
      request,
      `${process.env.API_LICENSING}${links.GET_PACKAGES}`,
    );
    if (response) {
      yield put({ type: consts.LOAD_PACKAGES, value: response });
    }
  } catch (err) {
    toastr.error(`Error loading packages`);
  }
}
export function* loadFeatures() {
  try {
    const response = yield call(
      request,
      `${process.env.API_LICENSING}${links.GET_FEATURES}`,
    );
    if (response) {
      yield put({ type: consts.LOAD_FEATURES, value: response });
    }
  } catch (err) {
    toastr.error(`Error loading features`);
  }
}
export function* loadWarehouses() {
  try {
    const response = yield call(
      request,
      `${process.env.API_DATAHALL}${links.GET_WAREHOUSES}`,
    );
    if (response) {
      yield put({ type: consts.LOAD_WAREHOUSES, value: response });
    }
  } catch (err) {
    toastr.error(`Error loading warehouses`);
  }
}
export function* loadUserTypes() {
  try {
    const response = yield call(
      request,
      `${process.env.API_DATAHALL}${links.GET_USERTYPES}`,
    );
    if (response) {
      yield put({ type: consts.LOAD_USERTYPES, value: response });
    }
  } catch (err) {
    toastr.error(`Error loading user types`);
  }
}

export default function* appSaga() {
  yield all([
    takeLatest(consts.SAGA_POWERUSERS_GETBYEMAIL, getByEmail),
    takeLatest(consts.SAGA_LOAD_COUNTRIES, loadCountries),
    takeLatest(consts.SAGA_UPDATE_COUNTRY, updateCountry),
    takeLatest(consts.SAGA_LOAD_PACKAGES, loadPackages),
    takeLatest(consts.SAGA_LOAD_FEATURES, loadFeatures),
    takeLatest(consts.SAGA_LOAD_WAREHOUSES, loadWarehouses),
    takeLatest(consts.SAGA_LOAD_USERTYPES, loadUserTypes),
  ]);
}
