import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import { createStructuredSelector } from 'reselect';
import { makeSelectCountries } from '../../App/selectors';
import { makeSelectConfigurations } from '../selectors';
import { connect } from 'react-redux';
import { SAGA_LOAD_CONFIGURATIONS, SAGA_UPDATE_CONFIG } from '../../App/constants';
import { compose } from 'redux';

const not = (a, b) => a.filter((value) => b.indexOf(value) === -1);
const intersection = (a, b) => a.filter((value) => b.indexOf(value) !== -1);
const union = (a, b) => [...a, ...not(b, a)];

const XRefConfigureCountries = (props) => {
  const [checked, setChecked] = useState([]);
  const [leftList, setLeftList] = useState(false);

  const leftChecked = intersection(checked, leftList);
  const {loadConfigurations, configs, countries} = props;

  useEffect(() => {
    if(!configs || configs.length === 0) {
      loadConfigurations();
    }
  }, [loadConfigurations, configs]);

  useEffect(() => {
    if(countries && countries.length > 0
      && configs && configs.length > 0
      && leftList === false
    ) {
      let configCountries = [];
      configs.forEach(x => configCountries = configCountries.concat(x.countries));
      setLeftList(not(countries.map(x => x.countryName), configCountries));
    }
  }, [countries, configs, leftList]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const updateConfigCountries = (config, countries) => {
    props.updateConfig({ ...config, countries: countries });
  };

  const handleCheckedRight = (config) => {
    updateConfigCountries(config,  [...config.countries].concat(leftChecked));
    setLeftList(not(leftList, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = (config) => {
    const rightChecked = intersection(checked, config.countries);
    setLeftList(leftList.concat(rightChecked));
    const temp = not(config.countries, rightChecked);
    updateConfigCountries(config, temp);
    setChecked(not(checked, rightChecked));
  };

  return (
    <div className="xref-countries-config">
      <div className="row">
        <div className="col-5">
          <Card className="countries-grid">
            <CardHeader
              className=""
              avatar={
                <Checkbox
                  onClick={handleToggleAll(leftList)}
                  checked={numberOfChecked(leftList) === leftList.length && leftList.length !== 0}
                  indeterminate={numberOfChecked(leftList) !== leftList.length && numberOfChecked(leftList) !== 0}
                  disabled={leftList.length === 0}
                  inputProps={{ 'aria-label': 'all items selected' }}
                />
              }
              title="List of countries"
              subheader={`${numberOfChecked(leftList)}/${leftList.length} selected`}
            />
            <Divider />
            <List className="countries-list" dense component="div" role="list">
              {leftList && leftList.map((value) => {
                const labelId = `transfer-list-all-item-${value}-label`;

                return (
                  <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                    <ListItemIcon>
                      <Checkbox
                        checked={checked.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={value} />
                  </ListItem>
                );
              })}
              <ListItem />
            </List>
          </Card>
        </div>
        <div className="col-7">
          {configs && configs.map(config => {
            const configCountries = config.countries;
            return (<div key={config._id} className="d-flex m-2">
                <div className="row align-items-center">
                  <div>
                    <button type="button" className="btn btn-sm btn-outline-secondary m-1" onClick={() => handleCheckedRight(config)}>&gt;</button>
                    <button type="button" className="btn btn-sm btn-outline-secondary m-1" onClick={() => handleCheckedLeft(config)}>&lt;</button>
                  </div>
                </div>
                <Card className="cfg-countries-grid">
                  <CardHeader className=""
                    avatar={
                      <Checkbox
                        onClick={handleToggleAll(configCountries)}
                        checked={numberOfChecked(configCountries) === configCountries.length && configCountries.length !== 0}
                        indeterminate={numberOfChecked(configCountries) !== configCountries.length && numberOfChecked(configCountries) !== 0}
                        disabled={configCountries.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                      />
                    }
                    title={`Countries in ${config.region}`}
                    subheader={`${numberOfChecked(configCountries)}/${configCountries.length} selected`}
                  />
                  <Divider />
                  <List className="cfg-countries-list" dense component="div" role="list">
                    {configCountries.map((value) => {
                      const labelId = `transfer-list-all-item-${value}-label`;
                      return (
                        <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                          <ListItemIcon>
                            <Checkbox
                              checked={checked.indexOf(value) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={value} />
                        </ListItem>
                      );
                    })}
                    <ListItem />
                  </List>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

XRefConfigureCountries.propTypes = {
  countries: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  configs: PropTypes.array,
  loadConfigurations: PropTypes.func,
  updateConfig: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  countries: makeSelectCountries(),
  configs: makeSelectConfigurations()
});

const mapDispatchToProps = (dispatch) => {
  return {
    loadConfigurations: () =>  dispatch({ type: SAGA_LOAD_CONFIGURATIONS }),
    updateConfig: (config) => dispatch({type: SAGA_UPDATE_CONFIG, config })
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(XRefConfigureCountries);
