import { all, call, takeLatest } from 'redux-saga/effects';
import * as consts from 'containers/App/constants';
import toastr from 'toastr';
import * as links from 'utils/links';
import { postRequest } from 'utils/request';

export function* getUserInfo(req) {
  try {
    const response = yield call(
      postRequest,
      `${process.env.API_DATAHALL}${links.GET_USER_INFO}`, {userId: req.value.licenseId});

    yield call(req.value.callback, response);
  } catch (err) {
    toastr.error(`Loading user info error ${err}`);
  }
}

export default function* pafSaga() {
  yield all([takeLatest(consts.SAGA_USER_INFO, getUserInfo)]);
}
