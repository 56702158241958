import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import injectReducer from 'utils/injectReducer';
import reducer from './reducer';
import * as consts from '../App/constants';
import { ActiveIcon, InactiveIcon} from 'components/icons/Icons';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import * as globalSelectors from '../App/selectors';
import TempPasswordButton from './TempPasswordButton';

const UserDetail = (props) => {
    const [emailLogs, setEmailLogs] = useState([]);
    const {getEmailsLogs} = props;
    const userInfo = props.data;

    useEffect(() => {
        getEmailsLogs(userInfo.email, (el) => {
            setEmailLogs(el.data);
        });
    }, [userInfo, getEmailsLogs]);

    const onUserEditClick = () => {
        props.onSelectUser(props.data);
        props.history.push('/useredit');
    };

    const onUserDelete = () => {
        let {data} = props;
        if(confirm(`You are going to delete user ${data.firstName} ${data.secondName} (${data.email}). \nAre you sure?`)){
            props.onUserDelete(data.email.toLowerCase(), props.loggedInUser.email.toLowerCase());
        }
    };

    const getFeature = (featureText) => {
        let feature = props.features.find(x=> x.text === featureText);
        if(!feature){
            return {
              text: featureText,
                description: '--- (NOT FOUND!)'
            };
        }
        return feature;
    };

    const downloadLicenseFile = () =>  {
        props.onDownloadLicenseFile(props.data.userId, (response) => {
                const element = document.createElement("a");
                const file = new Blob([response.data], {type: 'text/xml'});
                element.href = URL.createObjectURL(file);
                element.download = "ValidatedLicense.xml";
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
        });
    };

    const getFormattedDateTime = (date) => {
        let dt = new Date(date);
        return `${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()}  ${dt.toLocaleTimeString()}`;
    };

  // eslint-disable-next-line react/prop-types
  const Mailto = ({ email, subject, body, ...props }) => {
    return (
      <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`} className="btn btn-outline-info btn-sm me-2">
        {props.children}
      </a>
    );
  };
  Mailto.propTypes ={
    children: PropTypes.string,
  };

  const body = `Dear ${userInfo.firstName} ${userInfo.secondName}!%0D%0A%0D%0A
  Your SSP G8/DThermX has been updated.%0D%0A
  If you use DThermX, just log-in and enjoy. To activate your new license for the SSP G8, please start SSP G8 while
   being connected to the Internet. The license should be downloaded automatically.
  %0D%0ABest regards,%0D%0ASWEP International AB`;


  let isSendLicenseFileDisabled = userInfo.sspPlatforms && !userInfo.sspPlatforms.some(x => x === 'DESKTOP');
  let sendLicenseFileTitle = isSendLicenseFileDisabled ? 'Available if desktop platform is active only': '';

  //console.log('UserDetails', props, userInfo);
  return (
      <div className="user-detail">
          <div className="row">
              <div className="col-5">
                  <div className="row g-3">
                    <div className="col-6">
                      <label htmlFor="fullname" className="form-label ud-label">Name</label>
                      <div id="fullname" className="">{userInfo.firstName} {userInfo.secondName}</div>
                    </div>
                    <div className="col-6">
                      <label className="form-label ud-label" htmlFor="email">Email</label>
                      <div className="" id="email">{userInfo.email}</div>
                    </div>
                  </div>
                  <div className="row pt-3">
                      <div className="col-6">
                        <label className="form-label ud-label" htmlFor="Company">Company</label>
                        <div id="Company">{userInfo.company}</div>
                      </div>
                      <div className="col-6">
                        <label className="form-label ud-label" htmlFor="Phone">Phone</label>
                        <div id="Phone">{userInfo.phone}</div>
                      </div>
                  </div>
                  <div className="row pt-3">
                      <div className="col-6">
                        <label className="form-label ud-label" htmlFor="Country">Country</label>
                        <div id="Country">{userInfo.country}</div>
                      </div>
                      <div className="col-6">
                        <label className="form-label ud-label" htmlFor="SKA">SKA</label>
                        <div id="SKA">{userInfo.ska ? "Yes" : "No"}</div>
                      </div>
                  </div>
                  <div className="row pt-3">
                      <div className="col-6">
                        <label className="form-label ud-label" htmlFor="Address">Address</label>
                        <div id="Address">
                            <div style={{overflowWrap:"break-word" }}>{userInfo.address}</div>
                        </div>
                      </div>
                      <div className="col-6">
                        <label className="form-label ud-label" htmlFor="isActiveWeb">Address</label>
                        <div id="isActiveWeb">{userInfo.isApproved ? "Yes" : "No" }</div>
                      </div>
                  </div>
                  <div className="row pt-3">
                      <div className="col-6">
                        <label className="form-label ud-label" htmlFor="userType">User type</label>
                        <div id="userType">{userInfo.userType}</div>
                      </div>
                      <div className="col-6">
                        <label className="form-label ud-label" htmlFor="Comments">Comments</label>
                        <div id="Comments">{userInfo.comments }</div>
                      </div>
                  </div>
                  <div className="row pt-3">
                      <div className="col-6">
                        <label className="form-label ud-label" htmlFor="Warehouse">Warehouse</label>
                        <div id="Warehouse">{userInfo.warehouse}</div>
                      </div>
                      <div className="col-6">
                        <label className="form-label ud-label" htmlFor="licenseExpiryDate">License Expiry Date</label>
                        <div id="licenseExpiryDate">{getFormattedDateTime(userInfo.license.licenseExpires)}</div>
                      </div>
                  </div>
              </div>
              <div className="col-6">
            <div className="row">
                <div className="col-auto">
                  <label className="form-label ud-label" htmlFor="Packages">Packages</label>
                  <div id="Packages" className="packages-wrapper">
                    <table className="table table-sm table-light" style={{fontSize:"11px", marginBottom:"0px"}}>
                        <tbody>
                        {userInfo.license.packages && userInfo.license.packages.map(p => (
                            <tr key={p.name}>
                                <td>{p.name}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                  </div>
                </div>
            </div>
                  <div className="row pt-3">
                      <div className="col-12">
                        <label className="form-label ud-label" htmlFor="additionalFeatures">Additional features</label>
                        <div id="additionalFeatures" className="packages-wrapper">
                          <table className="table table-sm table-light" style={{fontSize:"11px", marginBottom:"0px"}}>
                              <tbody>
                              {userInfo.license.features && userInfo.license.features.map(f => (
                                  <tr width="fit-content" key={f}>
                                      <td>{f}</td>
                                      <td>{getFeature(f).description}</td>
                                  </tr>
                              ) )}
                              </tbody>
                          </table>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="row pt-3">
              <div className="col-12">
                    <label className="form-label ud-label" htmlFor="Platforms">Platforms</label>
                      {userInfo.sspPlatforms &&
                          <div id="Platforms">
                                <div>{userInfo.sspPlatforms.some(x => x.toUpperCase() === 'DESKTOP') ? <ActiveIcon /> : <InactiveIcon />} Desktop ({userInfo.footprints ? userInfo.footprints.length : 0} active licenses)</div>
                                  <table className="table table-striped table-sm table-light table-bordered platforms-tbl" style={{fontSize:"11px", marginBottom:"0px"}}>
                                      <tbody>
                                      {userInfo.footprints && userInfo.footprints.map((f,i) => (
                                          <tr key={f.footprint}>
                                              <td width="25px">{i + 1}</td>
                                              <td><b>Created on</b></td>
                                              <td>{getFormattedDateTime(f.createdOn)}</td>
                                              <td><b>Verified</b></td>
                                              <td>
                                                  {f.verifiedOn && getFormattedDateTime(f.verifiedOn)
                                                  || <span className="badge bg-warning text-dark">Not verified</span>
                                              }
                                              </td>
                                              <td><b>Version</b></td>
                                              <td>{f.appVersions && f.appVersions.g8 ? f.appVersions.g8 : ''}</td>
                                              <td><b>Last login date</b></td>
                                              <td>{getFormattedDateTime(f.lastLoginDate)}</td>
                                              {!f.verifiedOn &&
                                                  <td><button type="button" className="btn btn-warning btn-sm" style={{fontSize:"10px"}}
                                                      onClick={() => props.onSendVerificationEmailForFootprint(userInfo.email, f.footprint)}
                                                      title="Send verification email">Send Ver. Email</button>
                                                  </td>}
                                          </tr>
                                      ) )}
                                      </tbody>
                                  </table>
                                <div id="Platforms">
                                    <div className="col-12 ps-0">{userInfo.auth ? <ActiveIcon /> : <InactiveIcon />} Web</div>
                                    <div className="col-12 ps-4 row info-section">
                                        <div className="col-4">Is verified: {userInfo.auth && userInfo.auth.isVerified ?
                                          (<span><ActiveIcon size={14} style={{color:"green"}} /> (Last login: {getFormattedDateTime(userInfo.lastLoginDate)})</span>) : (<span><InactiveIcon size={14} style={{color:"red"}}/></span>)}
                                        </div>
                                        <div className="col">
                                          {userInfo.auth && !userInfo.auth.isVerified ?
                                            (<button type="button" className="btn btn-warning btn-sm ml3" style={{fontSize:"10px"}}
                                                onClick={() => props.onSendVerificationEmailForDThermX(userInfo.email)}
                                                title="Send verification email">Send Ver. Email</button>) : (<span/>)}
                                        </div>
                                    </div>
                                </div>
                      </div>}
              </div>
          </div>
          <div className="row pt-3 pb-4">
            <div className="col-12">
              <label className="form-label ud-label" htmlFor="emails">Emails</label>
                <div className="email-logs" id="emails">
                    <table className="email-logs-tbl table table-sm table-light">
                        <thead>
                            <tr>
                            <th>Sent on</th>
                            <th>Email subject</th>
                            <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {emailLogs.sort((a,b) => b.timestamp - a.timestamp).map((e,i) => {
                            return (
                                <tr key={i}>
                                    <td>{moment.unix(e.timestamp).format("dddd, MMMM Do YYYY, h:mm:ss a")}</td>
                                    <td>{e.subject}</td>
                                    <td>{e.event}</td>
                                </tr>
                                );
                            })}

                        </tbody>
                    </table>
                </div>
              </div>
          </div>
          <div className="row">
              <div className="col detail-menu">
                  <button type="button" className="btn btn-outline-success btn-sm" onClick={onUserEditClick}>Edit</button>
                  {/* <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => this.props.onSendVerificationEmail([this.props.data.userId])}>Send verification email</button> */}
                  <button type="button" className="btn btn-outline-secondary btn-sm" disabled={isSendLicenseFileDisabled} title={sendLicenseFileTitle} onClick={() => props.onSendLicenseFile(props.data.userId)}>Send license file</button>
                  <button type="button" className="btn btn-outline-dark btn-sm" disabled={isSendLicenseFileDisabled} title={sendLicenseFileTitle} onClick={downloadLicenseFile}>Download license file</button>
                  {/* <a href={`mailto:${userInfo.email`} className="btn btn-outline-info btn-sm me-2">Send email</a> */}
                  <Mailto email={userInfo.email} subject="Your SSP G8/DThermX license has been updated" body={body}>
                    Send email
                  </Mailto>
                  <TempPasswordButton onSetTemporaryPassword={props.onSetTempPwd} email={userInfo.email} />
                  <button type="button" className="btn btn-outline-danger btn-sm" onClick={onUserDelete}>Delete</button>
              </div>
          </div>
      </div>
  );
};

UserDetail.propTypes = {
    data: PropTypes.object,
    history: PropTypes.object,
    getEmailsLogs: PropTypes.func,
    onSelectUser: PropTypes.func,
    onUserDelete: PropTypes.func,
    loggedInUser: PropTypes.object,
    features: PropTypes.array,
    onDownloadLicenseFile: PropTypes.func,
    onSendVerificationEmailForFootprint: PropTypes.func,
    onSendVerificationEmailForDThermX: PropTypes.func,
    onSendLicenseFile: PropTypes.func,
    onSetTempPwd: PropTypes.func
};

function mapDispatchToProps(dispatch) {
    return {
        onSelectUser: (user) => { dispatch({type: consts.SELECT_USER_FOR_EDIT, user }); },
        // onSendVerificationEmail: (user) => { dispatch({type: consts.SAGA_USER_SEND_VERIFICATION, users:user }); },
        onSendVerificationEmailForFootprint: (email, footprint) => { dispatch({type: consts.SAGA_USER_SEND_VERIFICATION_FOR_FOOTPRINT, email: email, footprint:footprint }); },
        onSendVerificationEmailForDThermX: (email) => { dispatch({type: consts.SAGA_USER_SEND_VERIFICATION_FOR_DTHERMX, email: email }); },
        onSetTempPwd: (email, temporaryPassword) => { dispatch({type: consts.SAGA_SET_TEMP_PASSWORD, email: email, temporaryPassword: temporaryPassword }); },
        onSendLicenseFile: (userId) => { dispatch({type: consts.SAGA_USER_SEND_LICENSE_FILE, userId: userId }); },
        onDownloadLicenseFile: (userId, callback) => { dispatch({type: consts.SAGA_USER_DOWNLOAD_LICENSE_FILE, userId: userId, callback});},
        onUserDelete: (email, deletedBy) => { dispatch({type: consts.SAGA_USER_DELETE, value:{email, deletedBy }}); },
        getEmailsLogs: (email, callback) => { dispatch({type: consts.SAGA_LOAD_EMAILS_LOGS, email, callback}); },
    };
}

const mapStateToProps = createStructuredSelector({
    loggedInUser: globalSelectors.selectLoggedInUser(),
    features: globalSelectors.makeSelectFeatures()
  });

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'home', reducer });

export default compose(withReducer, withConnect, withRouter)(UserDetail);
